import store from "@store/configureStore";
const { actions } = require("@redux/TouchpointRedux");

class TouchpointService {
  async fetch(programId) {
    return actions.fetch(store.dispatch, programId);
  }

  async submit(data) {
    const res = await actions.submit(store.dispatch, data);
    if(res.status==201){
      actions.fetch(store.dispatch, data?.programId);
    }
    
    return res;
  }

  async update(data) {
    const res = await actions.update(store.dispatch, data);
    if(res.status==200){
      actions.fetch(store.dispatch, data?.programId);
    }
    
    return res;
  }

  async delete(data) {
    const res = await actions.delete(store.dispatch, data);
    actions.fetch(store.dispatch, data?.programId);
    return res;
  }

  async submitAction(data) {
    const res = await actions.submitAction(store.dispatch, data);
    if(res.status==201){
      actions.fetch(store.dispatch, data?.programId);
    }
   
    return res;
  }

  async updateAction(data) {
    const res = await actions.updateAction(store.dispatch, data);
    if(res.status==200){
      actions.fetch(store.dispatch, data?.programId);
    }
    
    return res;
  }

  async deleteAction(data) {
    const res = await actions.deleteAction(store.dispatch, data);
    if(res.status==200){
      actions.fetch(store.dispatch, data?.programId);
    }
    
    return res;
  }

  async addForm(key, data) {
    store.dispatch(actions.addForm(key, data));
  }
}

const Touchpoint = new TouchpointService();

Object.freeze(Touchpoint);

export default Touchpoint;
