import Buttonlink from '@components/Button';
import FullName from '@components/FullName';
import { Popover, Transition } from '@headlessui/react'
import React from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import common from "@common/common";
import mail from "@services/mail-service";
import Management from '@services/management-service';
import Mixpanel from '@services/mixpanel-events';

type Props = {
    backpack?: boolean | null
    modalOpen?: boolean | null
    setModalOpen?: any
}

const MobileHeadETW = (props: Props) => {
    const { actions } = require("@redux/UserRedux");
    const fanId = useSelector((state: any) => (state.user?.user?.user?.id));
    const fanIdbackpack = useSelector((state: any) => (state.user?.fan?.id));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const management = Management.get();
    const cname = management?.creator?.username;
    const pname = management?.program?.name;

    const handleLogout = () => {
        if (props?.backpack) {
            props?.setModalOpen(false);
            dispatch({type:"LOGOUT"})
        } else {
            Mixpanel.send("Logged Out");
            dispatch(actions.setLogout(true));
            navigate(`/${cname}/${pname}/logout`);
        }
    };

    const contactUs = () => {
        window.location.href = `mailto:${process.env.REACT_APP_CONTACT_US_MAIL}`;
      }
      
    return (
        <div className={`${!props?.backpack ? "lg:hidden pt-5 pr-5":"-mt-2 pr-2"} flex justify-end items-center `}>
            <Popover.Group className="flex items-center justify-between gap-x-10">
                <Popover className="relative">
                    {({ open }) => (
                        <>
                            {!props?.backpack &&
                                (open ? <Popover.Button
                                    className={
                                        "flex items-center outline-none hover:opacity-80"
                                    }>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-h-6 body-inlay-primary">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </Popover.Button> :
                                    <Popover.Button
                                        className={
                                            "flex items-center outline-none hover:opacity-80"
                                        }>
                                        <svg className="h-6 w-6 body-inlay-primary" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <line x1="4" y1="6" x2="20" y2="6" />  <line x1="4" y1="12" x2="20" y2="12" />  <line x1="4" y1="18" x2="20" y2="18" /></svg>

                                    </Popover.Button>)
                            }

                            <Transition
                                show={!props?.backpack ? open:props?.modalOpen}
                                as={React.Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1">
                                <Popover.Panel className="absolute right-0 z-50 mt-3 transform">
                                    <div className="relative bg-body shadow-[0px_6px_40px_0px_#0000001F] p-[20px] rounded-lg w-[340px] h-[340px]">
                                        <div className="p-[20px] bg-tile rounded-xl h-[121px] flex flex-col gap-3 justify-center items-center">
                                            <p className={`font-semibold text-[20px] leading-7 ${props?.backpack ? 'text-white':'tile-inlay-primary'}`}>
                                                <FullName/>
                                            </p>
                                            <Buttonlink
                                                onClick={handleLogout}
                                                className="cursor-pointer text-[14px] p-[7px_18px] btn-inlay-primary bg-btn w-max text-center rounded-full"
                                                text="log out"
                                            />
                                        </div>
                                        <div className="flex flex-col mt-5 gap-[25px] justify-center items-center">
                                            <div>
                                                <a
                                                    target="_blank"
                                                    href={common.term_of_service_url}
                                                    className="block body-inlay-primary font-normal leading-5 text-center text-[16px] cursor-pointer relative" rel="noreferrer">
                                                    Terms of Use
                                                </a>
                                            </div>
                                            <div>
                                                <Buttonlink
                                                    onClick={contactUs}
                                                    className="block body-inlay-primary font-normal leading-5 text-center text-[16px] cursor-pointer relative"
                                                    text="Contact Encore"
                                                />
                                            </div>
                                            <div>
                                                <a
                                                    onClick={()=>mail.summary(fanId || fanIdbackpack)}
                                                    className="block body-inlay-primary font-normal leading-5 text-center text-[16px] cursor-pointer relative">
                                                    Request Data Summary
                                                </a>
                                            </div>
                                            <div>
                                                <a
                                                    onClick={()=>mail.removal(fanId || fanIdbackpack)}
                                                    className="block body-inlay-primary font-normal leading-5 text-center text-[16px] cursor-pointer relative">
                                                    Request Data Removal
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </>
                    )}
                </Popover>
            </Popover.Group>
        </div>
    )
}

export default MobileHeadETW