import store from "@store/configureStore";

class SocialService {
   params;
   popupWindow;
   intervalId = null;

  async connect(type, url, state) {

    const isMobile = window.innerWidth <= 800 && window.innerHeight <= 600; // Adjust the values as needed
    const width = isMobile ? window.innerWidth : 500;
    const height = isMobile ? window.innerHeight : 600;
    if(store.getState().creator?.program?.config?.type=="ecommerce"){
      url += "&state=" + `${state}~popup`;
      // open url that redirects again
      window.open(`/redirect?url=${encodeURIComponent(url)}`, '_blank', `width=${width},height=${height},toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no`);

      // window.open(url, '_blank', `width=${width},height=${height},toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no`);
    } else {
      url += "&state=" +state;
      window.open(url, "_self");
      return;
    }

  }
}
const Social = new SocialService();

export default Social;
