import Api from "@services/api";
import { toast } from "react-toastify";
import logo_letter from "@assets/logo_letter.png";

export const handleMarketProgramLogin: any = async (
	id,
	creatorId,
	token,
	touchpoint,
	fan,
	heading,
	dispatch,
	setLoader,
) => {
	if (token) {
		setLoader(id);
		const userData = {};
		userData["creatorId"] = creatorId;
		userData["programId"] = id;
		const json = await Api.login(userData);
		if (404 === json?.status || 207 === json?.status) {
			userData["extraInfo"] = {};
			const jsonn = await Api.signup(userData);
			if (jsonn?.status === 201) {
				shares(jsonn?.data?.data);
			} else {
				toast.error(jsonn?.data?.message || "Error on program sign up", {
					icon: () => <img alt="logo" src={logo_letter} />,
				});
			}
		} else if (200 === json?.status) {
			shares(json?.data?.data);
		}
	}

	async function shares(fanInfo) {
		const authorizeData = {
			programId: id,
			fanId: fanInfo?.id || fan?.id,
			actionId: touchpoint?.actions[0]?.id || "",
			tpId: touchpoint?.id || "",
			pointsEarned: touchpoint?.actions[0]?.points || 0,
			details: {
				phoneNumber: fanInfo?.phoneNumber || fan?.phoneNumber,
			},
		};

		if (authorizeData?.actionId) {
			await Api.authorizeActionItem(authorizeData);
		}
		redeem(fanInfo);
	}

	async function redeem(fanInfo) {
		const redeemData = {
			rewardId: "duy",
			programId: id,
			name: heading,
			fanId: fanInfo?.id || fan?.id,
		};
		const json = await Api.redemptionProgram(redeemData);
		if (json?.status) {
			dispatch({
				type: "SET_REDEEM_PROGRAMS",
				data: { id: id, info: json?.data?.data, fanInfo:fanInfo },
			});
		}
	}
};
