import { useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import Frame from "@assets/Frame.png";
import Loader from "@components/Loader";

const QrPopUp = props => {
  const { type, click } = props;
  const form = useSelector(
    (state: any) => state.touchpoint?.form?.[type+type] || {},
  );
  const [loader, setLoader] = useState(true);

  const data = (form?.qrcode || {});

  return (
    <>
      {/* <button
        onClick={() => setIsOpen(true)}
        className="border-[#000]/70 text-[#000]/70 w-[92px] tracking-[0.02em] flex items-center justify-center bg-transparent border-[1.5px] rounded-full h-[35px] font-normal text-[16px] cursor-pointer transition-all duration-700 ease-in-out"
      >
        Connect
      </button> */}
      <Transition appear show={Object.keys(data).length>0} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={()=>click({})}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-screen max-w-[700px] h-[531px]  transform overflow-hidden rounded-2xl ${props.isFan? "bg-[#1D1D1D]":"bg-[#F6F2E9]"} px-6 text-left align-middle shadow-xl transition-all`}>
                    <div className="flex items-center justify-end relative">
                        <button
                          onClick={() => {
                            click({});
                          }}
                          className=""
                        >
                          <svg className="h-8 w-8 text-red-500 -right-2 top-3 absolute"  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="18" y1="6" x2="6" y2="18" />  <line x1="6" y1="6" x2="18" y2="18" /></svg>
                        </button>
                      </div>
                  <div>

                 
                    <div className="mb-3 text-right absolute right-[20px] top-[20px]">
                      <p
                        onClick={()=>click({})}
                        className="font-semibold text-[14px] cursor-pointer inline-block"
                      ></p>
                    </div>
                    <div className="mt-[75px]">
                      <h1 className={`text-center text-[24px]  mb-[50px] ${props.isFan?"text-white":"text-[#140943]"}`}>
                        Your QR code is now active!
                      </h1>

                      <div className="items-center flex flex-col flex-wrap  justify-center">
                        <div className="w-[258px] h-[258px] mt-3 ">
                          {/* <QRCode value={text} /> */}
                            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">

                            {loader && <Loader height={30} width={30} white={false} />}
                            </div>
                            <img src={data?.config?.qrCodeUrl} onLoad={()=>setLoader(false)} className="mx-auto" />                          
                        
                        </div>
                        <div className="mt-3 ">
                          <div  className={`flex items-center w-[150px] h-[44px] ${props.isFan?"bg-tile text-black":" bg-[#F6104E] border text-white"} rounded-[50px]`}>
                          <a
                            href={data?.config?.qrCodeUrl}
                            download
                          >
                            <div className="flex flex-row justify-center items-center">
                              <span className="px-[13px]">
                              <svg className="h-8 w-8 text-inherit"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
                              </svg>

                              </span>
                              <span className="text-[16px] text-inherit">
                                  Download
                                </span>
                            </div>
                          </a>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
export default QrPopUp;
