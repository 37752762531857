import * as React from "react";
import { useSelector } from "react-redux";
import Loader from "@components/Loader";
import Touchpoint from "@services/touchpoint-service";
import ConfigField from "./ConfigField";
import Field from "./Field";

const mergeObjectsWithAllowedKeys = (obj1, obj2, allowedKeys) => {
  const mergedObject = { ...obj1 };

  for (const key of allowedKeys) {
    if (!mergedObject.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
      mergedObject[key] = obj2[key];
    }
  }

  return mergedObject;
};

const Form: React.FC<any> = ({ item, parent }) => {
  const TouchpointKey = parent.type;
  const kitem = parent.type;
  const program = useSelector(
    (state: any) => state.user?.user?.user?.programs?.[0] || {},
  );
  const user = useSelector(
    (state: any) => state.user?.user?.user || {},
  );
  const [error, setError] = React.useState("");
  const [isShow, setIsShow] = React.useState(false);

  const touchpoint = useSelector(
    (state: any) =>
      (state.touchpoint?.data || []).filter(
        (i) => i.type == TouchpointKey,
      )?.[0] || {},
  );

  const form = useSelector(
    (state: any) => state.touchpoint?.form?.[TouchpointKey] || {},
  );

  const isLoading = useSelector(
    (state: any) => state.touchpoint?.isLoading?.[TouchpointKey] || false,
  );

  const submitAction = async () => {
    setError("");
    const mergedObject = form;
    const defaultConfig = {};
    const keyValuePairs = Object.entries(parent?.forms?.config || {});
    keyValuePairs.map((item: any) => {
      defaultConfig[item?.[0]] = item?.[1]?.value;
    });

    let config = mergeObjectsWithAllowedKeys(
      {},
      form,
      Object.keys(parent?.forms?.config),
    );

    if (parent?.forms?.title?.enabled && (mergedObject?.title || "") == "") {
      setError("Title are required");
      return;
    } else if (
      parent?.forms?.points?.enabled &&
      (mergedObject?.points || 0) < 1
    ) {
      setError("Points are required");
      return;
    }

    const data = {
      programId: program?.id,
      tpId: touchpoint?.id,
      type: TouchpointKey,
      action: kitem,
      title: mergedObject?.title,
      description: mergedObject?.description,
      points: mergedObject?.points,
      config: { ...defaultConfig, ...config, active:true },
    };
    const json = await Touchpoint.submitAction(data);
    if (json !== undefined) {
      if (json.status == 201) {
        setIsShow(false);
      }
    }
    if(TouchpointKey=="qrcode"){
      setTimeout(()=>{
        viewAction(json?.data?.data?.actions?.[0]);
      },2000)
      

    }
  };

  const addForm = (key, value) => {
    const data = Object.assign({}, form);
    data[key] = value;
    Touchpoint.addForm(TouchpointKey, data);
  };

  const viewAction = (action) => {
    const data = Object.assign({}, form);
    data["qrcode"] = action;
    Touchpoint.addForm(TouchpointKey+TouchpointKey, data);
  }


  const forms = Object.keys(parent.forms || {});

  const actionItem = (action) => {
    return (
      <div>
        {forms.map((key, index) => {
          const actionForm = (parent?.forms || {})?.[key];
          if (key !== "config") {
            if (!actionForm?.enabled) return <></>;
            return (
              <div key={index}>
                <Field
                  config={actionForm}
                  form={form}
                  action={action}
                  actionKey={key}
                  addForm={addForm}
                />
              </div>
            );
          } else {
            const configs = Object.keys(actionForm || {});
            return configs.map((itemKey, index) => {
              const actionFormConfig = (actionForm || {})?.[itemKey];
              if (actionFormConfig?.enabled)
                if (["limit", "supply", "multiplier"].includes(itemKey)) {
                  return (
                    <div key={index}>
                      <ConfigField
                        config={actionFormConfig}
                        form={form}
                        action={action}
                        actionKey={itemKey}
                        addForm={addForm}
                      />
                    </div>
                  );
                } else {
                  return (
                    <>
                      <Field
                        config={actionFormConfig}
                        form={form}
                        action={action}
                        actionKey={itemKey}
                        addForm={addForm}
                      />
                    </>
                  );
                }
            });
          }
        })}

        {!!error && (
          <div className="mt-4">
            <p className="text-red-400">{error}</p>
          </div>
        )}

        {/* <div className="flex flex-wrap justify-start gap-3 items-center mt-[10px] "></div> */}

        <div className="mt-[20px] flex items-center gap-x-[20px] ">
          <button
            disabled={Object.keys(form || {}).length == 0}
            onClick={() => submitAction()}
            className="disabled:opacity-50 border-[#000] text-[#000] w-[92px]
                 tracking-[0.02em] flex items-center justify-center bg-transparent border-[1.5px] rounded-full h-[35px] font-normal text-[16px] cursor-pointer transition-all duration-700 ease-in-out"
          >
            {isLoading ? (
              <Loader height={30} width={30} white={false} creator={true} />
            ) : (
              "save"
            )}
          </button>

          {!action?.id && (
            <button
              onClick={() => setIsShow(!isShow)}
              className=" border-[#f6104e] c-primary w-[92px]
                               tracking-[0.02em] flex items-center justify-center bg-transparent border-[1.5px] rounded-full h-[35px] font-normal text-[16px] cursor-pointer transition-all duration-700 ease-in-out"
            >
              cancel
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <>
        <button
          onClick={() => setIsShow(!isShow)}
          className=" border-[#000]/70 text-[#000]/70 w-[172px] mb-[40px] mt-[40px]

                tracking-[0.02em] flex items-center justify-center bg-transparent border-[1.5px] rounded-full h-[35px] font-normal text-[16px] cursor-pointer transition-all duration-700 ease-in-out"
        >
          + add touchpoint
        </button>
        {isShow && actionItem({})}
      </>
    </div>
  );
};

export default Form;
