import * as React from "react";
import { useSelector } from "react-redux";
import { Switch } from "@headlessui/react";
import Loader from "@components/Loader";
import Touchpoint from "@services/touchpoint-service";
import ConfigField from "./ConfigField";
import Field from "./Field";

const mergeObjectsWithAllowedKeys = (obj1, obj2, allowedKeys) => {
  const mergedObject = { ...obj1 };

  for (const key of allowedKeys) {
    if (!mergedObject.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
      mergedObject[key] = obj2[key];
    }
  }

  return mergedObject;
};

const List: React.FC<any> = ({ item, parent }) => {
  const TouchpointKey = parent.type;
  const kitem = item.action;
  const program = useSelector(
    (state: any) => state.user?.user?.user?.programs?.[0] || {},
  );
  const [error, setError] = React.useState("");
  const [isCopy, setIsCopy] = React.useState(false);

  const touchpoint = useSelector(
    (state: any) =>
      (state.touchpoint?.data || []).filter(
        (i) => i.type == TouchpointKey,
      )?.[0] || {},
  );

  const action = item || {};

  const form = useSelector(
    (state: any) => state.touchpoint?.form?.[action?.id] || {},
  );

  const isActionLoading = useSelector(
    (state: any) => state.touchpoint?.isLoading?.[action?.id] || false,
  );

  const updateAction = async (datum) => {
    const mergedObject = { ...datum, ...form };
    setError("");
    const defaultConfig = {};
    const keyValuePairs = Object.entries(parent?.forms?.config || {});
    keyValuePairs.map((item: any) => {
      defaultConfig[item?.[0]] = item?.[1]?.value;
    });
    const mergedObjectConfig = { ...datum.config, ...form };

    let config = mergeObjectsWithAllowedKeys(
      {},
      mergedObjectConfig,
      Object.keys(parent?.forms?.config),
    );

    if (parent?.forms?.title?.enabled && (mergedObject?.title || "") == "") {
      setError("required title");
      return;
    } else if (
      parent?.forms?.points?.enabled &&
      (mergedObject?.points || 0) < 1
    ) {
      setError("Points required");
      return;
    }

    const data = {
      id: datum?.id,
      programId: program?.id,
      tpId: touchpoint?.id,
      type: TouchpointKey,
      action: kitem,
      description: mergedObject?.description,
      title: mergedObject?.title,
      points: mergedObject?.points,
      config: { ...defaultConfig, ...config, active:true },
    };
    await Touchpoint.updateAction(data);
  };

  const deleteAction = async () => {
    if (action?.id) {
      const data = {
        id: action?.id,
        programId: program?.id,
        tpId: touchpoint?.id,
        action: kitem,
      };
      await Touchpoint.deleteAction(data);
    }
  };

  const addForm = (key, value) => {
    const data = Object.assign({}, form);
    data[key] = value;
    Touchpoint.addForm(action?.id, data);
  };

  const editAction = (data) => {
    Touchpoint.addForm(action?.id, data);
  };

  const toggleAction = async (action) => {
    if (action?.id) {
      const data = {
        id: action?.id,
        programId: program?.id,
        tpId: touchpoint?.id,
        type: TouchpointKey,
        action: kitem,
        config: {...action?.config, active:!(action?.config?.active || false)},
      };
      await Touchpoint.updateAction(data);
    }
  }

  const viewAction = (action) => {
    const data = Object.assign({}, form);
    data["qrcode"] = action;
    Touchpoint.addForm(TouchpointKey+TouchpointKey, data);
  }

  const copyLink = (link) => {
    navigator.clipboard.writeText(link).then(() => {
      setIsCopy(true);
    const timer = setTimeout(() => {
      setIsCopy(false);
    }, 1000);
    return () => clearTimeout(timer);
    });
    

  }

  const forms = Object.keys(parent.forms);

  const actionItem = (action) => {
    return (
      <div>
        {forms.map((key, index) => {
          const actionForm = (parent?.forms || {})?.[key];
          if (key !== "config") {
            if (!actionForm?.enabled) return <></>;
            return (
              <div key={index}>
                <Field
                  config={actionForm}
                  form={form}
                  action={action}
                  actionKey={key}
                  addForm={addForm}
                />
              </div>
            );
          } else {
            const configs = Object.keys(actionForm || {});
            return configs.map((itemKey, index) => {
              const actionFormConfig = (actionForm || {})?.[itemKey];
              if (actionFormConfig?.enabled)
                if (["limit", "supply", "multiplier"].includes(itemKey)) {
                  return (
                    <div key={index}>
                      <ConfigField
                        config={actionFormConfig}
                        form={form}
                        action={action}
                        actionKey={itemKey}
                        addForm={addForm}
                      />
                    </div>
                  );
                } else {
                  return (
                    <>
                      <Field
                        config={actionFormConfig}
                        form={form}
                        action={action?.config}
                        actionKey={itemKey}
                        addForm={addForm}
                      />
                    </>
                  );
                }
            });
          }
        })}

        {!!error && (
          <div className="mt-4">
            <p className="text-red-400">{error}</p>
          </div>
        )}

        {/* <div className="flex flex-wrap justify-start gap-3 items-center mt-[10px] "></div> */}

        <div className="mt-[20px] flex items-center gap-x-[20px] ">
          {Object.keys(form || {}).length>0 &&
          <>
          <button
            disabled={Object.keys(form || {}).length == 0}
            onClick={() => updateAction(action)}
            className="disabled:opacity-50 border-[#000] text-[#000] w-[92px]
                 tracking-[0.02em] flex items-center justify-center bg-transparent border-[1.5px] rounded-full h-[35px] font-normal text-[16px] cursor-pointer transition-all duration-700 ease-in-out"
          >
            {"Update"}
          </button>
          <button
            onClick={() => editAction({})}
            className=" border-[#f6104e] c-primary w-[92px]
                               tracking-[0.02em] flex items-center justify-center bg-transparent border-[1.5px] rounded-full h-[35px] font-normal text-[16px] cursor-pointer transition-all duration-700 ease-in-out"
          >
            cancel
          </button>
          </>
          }
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="flex items-center justify-between ">
        <h4 className="font-medium text-black text-[24px] leading-[31.2px]">
          {item?.title}
        </h4>
      </div>
      <p className="font-normal text-[black]/70 text-[18px] leading-[21.2px] mt-[7px] mb-[20px]">
        {item?.description}
      </p>
      {action.id == form?.id ? (
        actionItem(action)
      ) : (
        <>
          <div className="mt-[20px] flex items-center justify-end gap-x-[20px]">
            {action?.type == "qrcode" &&
            <>
            <button
              onClick={() => viewAction(action)}
              className=" border-[#000] text-[#000] p-2
tracking-[0.02em] flex items-center justify-center bg-transparent border-[1.5px] rounded-full h-[31px] font-normal text-[16px] cursor-pointer transition-all duration-700 ease-in-out"
            >
              view QR code
            </button>
            {action?.config?.eventUrl &&
            <button
            onClick={() => copyLink(action?.config?.eventUrl)}
            className=" border-[#000] text-[#000] p-2
tracking-[0.02em] flex items-center justify-center bg-transparent border-[1.5px] rounded-full h-[31px] font-normal text-[16px] cursor-pointer transition-all duration-700 ease-in-out"
          >
            {isCopy?"copied":"copy"}
          </button>
            }
            </>
            }
            <button
              onClick={() => editAction(action)}
              className=" border-[#000] text-[#000] w-[80px]
tracking-[0.02em] flex items-center justify-center bg-transparent border-[1.5px] rounded-full h-[31px] font-normal text-[16px] cursor-pointer transition-all duration-700 ease-in-out"
            >
              edit
            </button>
            {action?.totalEngagements>0?<>
              {isActionLoading ? (
          <div className="flex items-center h-[25px] w-[50px] shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out">
            <Loader height={30} width={30} white={false} creator={true} />
          </div>
        ) : (
          <Switch
            checked={action?.config?.active ? true : false}
            onChange={()=>toggleAction(action)}
            className={`${action?.config?.active ? "bg-[#0FA81D]" : "bg-[#A6A6A6]"}
relative flex items-center h-[25px] w-[50px] shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out`}
          >
            <span
              aria-hidden="true"
              className={`${
                action?.config?.active ? "translate-x-7" : "translate-x-1"
              }
pointer-events-none inline-block h-[19px] w-[19px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
        )}
        </>:
            <button
            onClick={() => deleteAction()}
            className=" border-[#f6104e] c-primary w-[80px]
tracking-[0.02em] flex items-center justify-center bg-transparent border-[1.5px] rounded-full h-[31px] font-normal text-[16px] cursor-pointer transition-all duration-700 ease-in-out"
          >
            {isActionLoading ? (
              <Loader height={30} width={30} white={false} creator={true} />
            ) : (
              "delete"
            )}
          </button>}
            
          </div>
        </>
      )}
    </div>
  );
};

export default List;
