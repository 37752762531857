import React, { useRef, useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import Button from "@components/Button";
import "./style.css";
import Api from "@services/api";

let cropper = null;

const ImageCropper = () => {
  const { actions } = require("@redux/LoaderRedux");
  const { actions:userActions } = require("@redux/UserRedux");

  const dispatch = useDispatch();

  let form = useSelector((state: any) =>
    Object.assign({}, state.loader.assetForm)
  );
  const creator = useSelector((state: any) =>
    Object.assign({}, state.user?.user?.user)
  );

  const imageRef = useRef(null);

  let touch = useRef(null);

  const [destination, setDestination] = useState("");
  const [isOpen, setIsOpen] = useState(true);

  const closeModal = () => {
    URL.revokeObjectURL(form.profileImage);
    form["profileImage"] = null;
    form["popup"] = false;
    dispatch(actions.addAssetForm(form));

    setIsOpen(false);
  };

  const apply = async () => {
    const data = new FormData();
    const canvas = touch.current
      .getCroppedCanvas({
        width: 300,
        height: 300,
      })
      .toBlob(async (blob: any) => {
        data.append("profileImage", blob);
        if(creator.programs?.[0]?.id){
          await Api.creatorUploadAssets(creator.id, creator.programs?.[0]?.id, data);
        }
        form["preview"] = URL.createObjectURL(blob);
        form["popup"] = false;
        dispatch(actions.addAssetForm(form));
        if(creator.programs?.[0]?.id){
          userActions.fetchCreatorAsset(dispatch, creator.id, creator.programs?.[0]?.id);
        }
       
      });
  };

  const onSliderChange = (value) => {
    const ratio = (value * 10) / 100;
    const containerData = touch.current.getContainerData();

    touch.current.zoomTo(ratio, {
      x: containerData.width / 2,
      y: containerData.height / 2,
    });
  };

  useEffect(() => {
    const cropper = new Cropper(imageRef.current, {
      movable: true,
      zoomable: true,
      scalable: false,
      cropBoxResizable: false,
      cropBoxMovable: true,
      aspectRatio: 1.5 / 1.5,
      toggleDragModeOnDblclick: false,
      highlight: false,

      crop: () => {
        touch.current = cropper;
      },
    });
  }, []);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-screen max-w-[685px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div>
                  <div className="flex items-center justify-between mb-3">
                    <p
                      onClick={closeModal}
                      className="font-semibold text-[14px] cursor-pointer ">
                      back{" "}
                    </p>
                    <button
                      className="border-[#000000] black w-[100px] flex items-center justify-center bg-transparent border-[1.5px]  rounded-full h-[35px] font-semibold text-[14px] cursor-pointer transition-all duration-700 ease-in-out focus-visible:outline-0"
                      onClick={apply}
                    ><span className="text-black">Apply</span></button>
                  </div>
                  <div className="md:w-[640px] h-[480px]">
                    <img
                      ref={imageRef}
                      src={form.profileImage ?? ""}
                      alt="Source"
                    />
                  </div>
                  {/* <img src={destination} className="img-preview" alt="Destination" /> */}
                </div>
                {/* <div className="mt-12" /> */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ImageCropper;
