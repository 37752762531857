import * as React from "react";
import { Switch } from "@headlessui/react";

const FieldComponent: React.FC<any> = ({
  config,
  form,
  action,
  actionKey,
  addForm,
}) => {


  const onChange = (e) => {
    let value = e.target.value;
    if(actionKey == "points"){
      value = parseInt(value.trim()) || "";

      if (value < 0 || isNaN(value) || value > 10000) {
        return;
      }
    }
    addForm(actionKey, value);
  };

  return (
    <div>
      <div className="pb-[20px]">
        <p className="text-black text-[15px] leading-[15px] mb-[10px]">
          {config?.label}
        </p>
        {actionKey == "description" ? (
          <textarea
            onChange={onChange}
            value={actionKey in form ? form?.[actionKey] : action?.[actionKey]}
            name={`key-${actionKey}`}
            placeholder={config?.placeholder}
            className="bg-white w-full mx-auto h-[100px] px-3 py-[13px] rounded-[10px] text-[18px] lg:placeholder:font-normal placeholder:font-light font-normal placeholder:text-[black]/30 text-black shadow-none outline-none"
          ></textarea>
        ) : (
          <input
            onChange={onChange}
            value={actionKey in form ? form?.[actionKey] : action?.[actionKey]}
            type="text"
            name={`key-${actionKey}`}
            placeholder={config?.placeholder}
            className={`bg-white w-${
              actionKey == "points" ? "[100px]" : "full"
            } mx-auto min-w-unset h-[45px] px-3 rounded-[10px] text-[18px] lg:placeholder:font-normal placeholder:font-light font-normal placeholder:text-[black]/30 text-black shadow-none outline-none`}
          />
        )}
      </div>
    </div>
  );
};

export default FieldComponent;
