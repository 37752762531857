import { Listbox, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import countries from "@helpers/countries";
import { CountryCode } from "../types";

interface CountryDropdownProps {
  onCountryChange?: (a: CountryCode) => void;
  selectedCountry?: CountryCode;
  backgroundColor?:string
}

export const CountryDropdown = ({
  onCountryChange,
  selectedCountry,
  backgroundColor = "bg-tile-form",
}: CountryDropdownProps) => {
  return (
    <div className={`shrink-0 `}>
      <Listbox value={selectedCountry} onChange={onCountryChange}>
        {({ open }) => {
          return (
            <>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  className={`${backgroundColor} absolute z-[9999999999] mt-1 no-scrollbar max-h-[360px] w-full max-w-[120px] overflow-auto rounded-md  py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm`}
                >
                  {countries.map((country, countryIdx) => (
                    <Listbox.Option
                      key={countryIdx}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 px-4 ${
                          active ? "bg-gray-100 text-gray-900" : "text-gray-900"
                        }`
                      }
                      value={country}
                    >
                      {({ selected }) => (
                        <div className="flex items-center gap-2">
                          <img
                            src={country?.flag}
                            alt="flag"
                            className="w-6 h-5 object-contain"
                          />
                          <span
                            className={`block truncate text-sm ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {country.callingCodes}
                          </span>
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
              {/**${ template == "ecommerce" ? "bg-primary" : "bg-body"}  on className */}
              <Listbox.Button
                className={`${
                  open ? "w-[120px] shadow-md" : ""
                } ${backgroundColor} mt-1 w-full cursor-default rounded-lg py-2 pl-3 text-left tile-inlay-primary`}
              >
                <div className="flex items-center gap-2 relative">
                  <img
                    src={selectedCountry?.flag}
                    alt="flag"
                    className="w-6 h-6 object-contain"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="5"
                    viewBox="0 0 8 5"
                    fill="none"
                    className={`${open && "transfrom rotate-180"}`}
                  >
                    <path
                      d="M0 4.5L4 0.5L8 4.5H0Z"
                      fill="black"
                      fill-opacity="0.3"
                    />
                  </svg>
                  {open && (
                    <span className={`block text-sm font-normal`}>
                      {selectedCountry.callingCodes}
                    </span>
                  )}
                </div>
              </Listbox.Button>
            </>
          );
        }}
      </Listbox>
    </div>
  );
};
