import React, { useEffect, useState } from "react";
import Loader from "@components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Api from "@services/api";
import check from "@assets/check.png";
import countries from "@helpers/countries";

import DOMPurify from 'dompurify';
import Management from "@services/management-service";
import { PhoneInput } from "@components/PhoneInput";
import Mixpanel from "@services/mixpanel-events";
import { CodeInput } from "@components/CodeInput";
import { klaviyoEvent } from "@helpers/common";

const ActivateEncore = (props: any) => {
	const { actions } = require("@redux/UserRedux");
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [numberError, setNumberError] = useState("");
	const [resendLoader, setResendLoader] = useState(false);
	const management = Management.get();
	const creator = useSelector((state: any) => state.creator);
	const pId = management?.program?.id ?? "market";
	const form = useSelector((state: any) => Object.assign({}, state.user.form?.[pId] || {}));
	const [codeError, setCodeError] = useState(form?.codeError ?? "");
	const [loader, setLoader] = useState(form?.loader);
	const eToken = useSelector((state: any) => state.user.token);
	const programs = useSelector((state: any) => state.user.programs);
	const authLoader = useSelector((state: any) => state.user.authLoader);
	const [toggleHeight, setToggleHeight] = useState(!eToken);
	const [step, setStep] = useState(form?.code ? true: false);
	const creator_id = useSelector((state: any) => state.creator.creator.id);
	const programId = useSelector((state: any) => state.creator.program.id);
	const cname = management?.creator?.username;
	const pname = management?.program?.name;
	const [localForm, setLocalForm] = useState<any>(form);
	const legalText = management?.program?.config?.data?.landing_page?.legalText;
	const cleanHTML = legalText ? DOMPurify.sanitize(legalText, {ADD_ATTR: ['target']}): null;
	
	const backPackLegal = programs[0]?.config?.data?.landing_page?.legalText


	useEffect(()=>{
		if(localForm?.etoken){
			Api.setClientToken(localForm?.etoken)
		}
	},[localForm.token]);

	const addForm = (key: string, value: any) => {
		let data = Object.assign({},localForm);
		data = {
			...data,
			[key]: value,
		};
		setLocalForm(data);
	};

	const handlePhoneSubmit = async (phoneNumber) => {
		Mixpanel.send('Phone Number Entered', null, {phone: phoneNumber})
		setLoader(true);
		const data = {
			type: "sms",
			query: "sendToken",
			phoneNumber: phoneNumber ?? localForm?.number
		};
		const res = await Api.phoneAuthentication(data);
		if (res?.status == 200) {
			setNumberError("");
			setStep(true);
			setLoader(false);
		} else {
			setNumberError(res?.data?.message?.[0] || "Invalid phone number");
			setLoader(false);
		}
		setLoader(false)
	};

	const resendHandle = async () => {
		setResendLoader(true);
		const data = {
			type: "sms",
			query: "sendToken",
			phoneNumber: localForm?.number,
		};
		const res = await Api.phoneAuthentication(data);
		if (res?.status == 200) {
			setResendLoader(false);
			setNumberError("");
			setStep(true);
		} else {
			setNumberError(res?.data?.message?.[0] || "Invalid phone number");
			setResendLoader(false);
		}
	};

	const handleLogin = async () => {
		setCodeError("");
		// Validate code input
		if (!codeValidation(localForm?.code)) return;
		Mixpanel.send('Code Entered')
		setLoader(true);
		dispatch(actions.addForm({[pId]: {...localForm, toggleHeight: true, loader:true}}));
		const data = {
			type: "sms",
			query: "verifyToken",
			phoneNumber: localForm?.number,
			verifyCode: localForm?.code,
			encore_hosted: true,
		};
		const res = await Api.phoneAuthentication(data);
		if (res?.status == 200) {
			if (props?.backpack) {
				dispatch({type: "SET_TOKEN", data: res?.data?.jwtToken})
				props?.setIsOpen(false)
			} else {
				dispatch(actions.addForm({[pId]: {...localForm, loader:false, toggleHeight:true, codeError: ""}}));
				enterProgram(res?.data?.jwtToken);
			}
			setCodeError("");
			setLoader(false);
		} else {
			dispatch(actions.addForm({[pId]: {...localForm, loader:false, toggleHeight:true, codeError: "Invalid verification code"}}));
			Mixpanel.send("Error Recieved", {page_path:'/earn', error_message:'Invalid verification code'})
			setCodeError("Invalid verification code");
			setLoader(false);
		}
	};

	const codeValidation = (value: string) => {
		const validCodeRegex = /^\d{6}$/;

		if (value.length === 0) {
			setCodeError("Verification code is required");
			return false;
		} else if (!validCodeRegex.test(value)) {
			setCodeError("Invalid verification code");
			return false;
		} else {
			setCodeError("");
			return true;
		}
	};

	const toggleHandler = () => {
		if(props.backpack){
			return false;
		}
		if(eToken){
			enterProgram(eToken)
		} else {
			setToggleHeight(!toggleHeight)
		}
		
	};

	const signupHandle = async (token) => {
		dispatch({type: "AUTH_LOADER", data: true})
		let userData = {
			creatorId: creator?.creator?.id,
			programId: creator?.program?.id,
		};

		userData["extraInfo"] = localForm?.extrainfo || {};
		dispatch(actions.addForm({[pId] : {...localForm}}))
		const json = await actions.doSignup(dispatch,userData, token, creator?.creator?.username, creator?.program?.name, "ecommerce");

		if (json.status == 201) {
			if (localForm?.number) {
				klaviyoEvent('phone_number', localForm?.number);
			}
			setTimeout(() => Mixpanel.send('Account Created'), 1000)
			navigate(`/${creator?.creator?.username}/${creator?.program?.name}/earn`);
		}
		dispatch({type: "AUTH_LOADER", data: false})
	};

	const authenticate = async (accessTokenJwt) => {
		Api.setClientToken(accessTokenJwt);

		const json = await actions.authenticate(
			dispatch,
			"ecommerce",
			"fans",
			creator_id,
			accessTokenJwt,
			programId,
			cname,
			pname
		);
		
		if (json?.status === 404 || json?.status === 207) {
			signupHandle(accessTokenJwt);
		} else if (json?.status == 200) {
			if (localForm?.number) {
				klaviyoEvent('phone_number', localForm?.number);
			}
			setTimeout(() => Mixpanel.send('Logged In'), 1000);
			navigate(`/${cname}/${pname}/earn`);
		}
	};

	const enterProgram = (token) => {
		authenticate(token);
	}

	const handlePhoneSubmitInput = (phoneNumber: string) => {
		addForm("number", phoneNumber);
		handlePhoneSubmit(phoneNumber);
	};

	const handleChangePhone = ()=> {
		dispatch(actions.addForm({[pId]: {number: localForm?.number, toggleHeight: true, codeError: "", step: false}}));
	}

	return (
		<>
			<div className="mt-2 relative">
				<div className={`absolute top-0 left-0 right-0 bottom-0 ${!props.backpack ? 'bg-tile' : 'bg-transparent'} opacity-60 rounded-[10px]`}></div>
						<div
							className={`relative p-[15px_10px] transition-all duration-700 ease-in-out`}
						>
							<div
								onClick={(e) => {
									toggleHandler();
								}}
								className={`${
									toggleHeight
										? "max-h-[100vh] transition-[max-height] ease-in-out duration-1000 "
										: "p-[10px_0px] lg:max-h-[40px] max-h-[40px] transition-all duration-700 ease-in-out rounded-[10px] relative overflow-hidden"
								}`}
							>
								<div className="flex cursor-pointer items-center justify-between">
									<div className={`flex ${props.backpack ? 'justify-center grow' : ''} items-center gap-[10px]`}>
										<h4
											data-testid={`title-${props.item?.action}`}
											className={`${props.backpack ? 'font-medium text-[24px] w-full leading-[30px] tracking-[0.02em] text-black text-center' : 'font-normal tracking-[0.02em] leading-5 tile-inlay-primary text-[16px]'}`}
										>
											{props?.backpack ? "Login to Encore": "Opt in to texts"}
										</h4>
									</div>
									<div>
										{authLoader && <Loader height={20} width={20} color={"#000"} />}
									</div>
								</div>

								{/* Input fields displayed conditionally */}
								<div
									className="mt-3"
									onClick={(e) => {
										e.stopPropagation();
									}}
								>
									{!eToken &&
									<PhoneInput
										disabledInput={step}
										phoneNumber={form?.number}
										onSubmit={step ? handleChangePhone : handlePhoneSubmitInput} 
										isLoading={loader && !step} 
										error={!step && numberError !== "" ? numberError : undefined} 
										buttonLabel={step ? "CHANGE" : "SUBMIT" }
									/>}
									{
										props.backpack && !step &&
										<p className="font-[300] text-[18px] mt-5 leading-[22px] text-center tracking-[0.02em] text-[black] opacity-70">
											{backPackLegal}
										</p>
									}
									{!!legalText && !step && 
										<div 
										className="font-[400] text-[12px] leading-[14px] tracking-[0.02em] text-[black] opacity-50 mt-[10px]"
										dangerouslySetInnerHTML={{ __html: cleanHTML }} 
									/>}
									{step && 
									<CodeInput 
										localForm={localForm}
										handleLogin={handleLogin}
										addForm={addForm}
										loader={loader}
									/>}
									{codeError && (
										<p className="text-[10px] mt-1 text-center font-normal text-red-500 ">
											{codeError}
										</p>
									)}
									{!localForm?.etoken && step &&
									<div>
										<p className="font-normal text-[14px] tracking-[0.02em] leading-5 tile-inlay-primary text-center mt-2">
											Didn't receive a code?
										</p>
										{resendLoader ? (
											<div className="mt-2">
												<Loader height={20} width={20} color={"#000"} />
											</div>
										) : (
											<p
												onClick={resendHandle}
												className="font-[600] text-[14px] tracking-[0.02em] leading-5 tile-inlay-primary text-center cursor-pointer"
											>
												Resend
											</p>
										)}
									</div>}
								</div>
							</div>
						</div>
			</div>
		</>
	);
};

export default ActivateEncore;