import * as React from "react";
import { Switch } from "@headlessui/react";

const ConfigField: React.FC<any> = ({
  config,
  form,
  action,
  actionKey,
  addForm,
}) => {
  const [isLimit, setIsLimit] = React.useState(false);

  const setFormData = (value) => {
    addForm(actionKey,value?1:-1);
    if(!value){
      setIsLimit(false)
    } else {
      setIsLimit(true)
    }
  }

  const changeNumber = (value) => {
    value = parseInt(value.trim()) || "";
    if(value<0 || isNaN(value) || value>10000){
      return;
    }
    addForm(actionKey, value)

  }

  React.useEffect(()=>{
    if(parseInt(action?.config?.[actionKey] || -1)<0){
      setIsLimit(false)
    } else {
      setIsLimit(true)
    }
  },[]);

  return (
    <div>
      <div className="pb-[20px]">
        <p className="text-black text-[15px] leading-[15px] mb-[10px]">
          {config?.lagend}
        </p>
        <p className="text-black text-[15px] font-normal mb-[10px] opacity-50">
          {config?.subtitle}
        </p>
        <div className="mt-[15px] flex items-center">
        <Switch
            checked={isLimit ? true : false}
            name={`${actionKey}-${action?.id}`}
            onChange={(e) => setFormData(e)}
            className={`${isLimit? "bg-[#0FA81D]" : "bg-[#A6A6A6]"}
relative flex items-center h-[15px] w-[30px] shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out`}
          >
            <span
              aria-hidden="true"
              className={`${isLimit ? "translate-x-4" : "translate-x-1"}
pointer-events-none inline-block h-[12px] w-[12px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
          <label className="text-[#000] text-[16px] font-semibold  leading-[18px] ml-[10px]">
            No limit
          </label>
        </div>
        {isLimit && (
          <div className="mt-[30px] ">
            <p className="text-black text-[15px] leading-[15px] mb-[10px]">
              {config?.label}
            </p>
            <div
              className={`${
                actionKey == "multiplier" &&
                "flex flex-wrap justify-start items-center"
              }`}
            >
              <input
                onChange={(e) => {
                  changeNumber(e.target.value || "")
                }}
                value={
                  actionKey in form
                    ? form?.[actionKey]
                    : action?.config?.[actionKey]
                }
                type="text"
                placeholder={config?.placeholder}
                className="bg-white mt-[10px] w-[100px]  h-[45px] px-3 rounded-[10px] text-[18px] lg:placeholder:font-normal placeholder:font-light font-normal placeholder:text-[black]/30 text-black shadow-none outline-none"
              />
              {actionKey == "multiplier" && (
                <div className="whitespace-nowrap mt-[10px]">
                  <label className="px-[10px]">Scan per:</label>
                  <select
                    onChange={(e) => {
                      addForm("timeMeasurement", parseInt(e.target.value));
                    }}
                    className="h-[45px]"
                    value={
                      form?.timeMeasurement ||
                      action?.config?.timeMeasurement ||
                      0
                    }
                  >
                    <option value=""></option>
                    <option value="0">Minutes</option>
                    <option value="1">Hours</option>
                    <option value="2">Days</option>
                    <option value="3">Weeks</option>
                  </select>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfigField;
