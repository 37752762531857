import { useSelector } from "react-redux";
import logo from "@assets/logo.png";
import logo_letter from "@assets/logo_letter.png";

const Logo = props => {
  let data = useSelector((state: any) =>
    Object.assign({}, state.creator.program)
  );
  return (
    <>
      {props.logo_letter ? (
        <img
          alt={"logo"}
          src={data?.assets?.profileImage ?? logo_letter}
          // onError={({ currentTarget }) => {
          //   currentTarget.onerror = null;
          //   currentTarget.src = logo;
          // }}
          {...props}
        />
      ) : (
        <img
          alt={"logo"}
          src={data?.assets?.profileImage ?? logo}
          // onError={({ currentTarget }) => {
          //   currentTarget.onerror = null;
          //   currentTarget.src = logo;
          // }}
          {...props}
        />
      )}
    </>
  );
};

export default Logo;
