import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleMarketProgramLogin } from "./marketPlace";
import CardFooter from "./cardFooter";
import AnimateCard from "./animateCard";
import { setMarketTheme } from "@helpers/common";

type Props = {};

export interface dataType {
	img: string;
	title: string;
	paragraph: string;
	description: string;
	category: string;
	date: string;
}

const MarketPlaceLanding = (props: Props) => {
	const programs = useSelector((state: any) => state.user.programs);
	const fan = useSelector((state: any) => state.user?.fan);
	const token = useSelector((state: any) => state.user.token);
	const reedemPrograms = useSelector((state: any) => state.user.reedemPrograms);
	const dispatch = useDispatch();
	const [loader, setLoader] = useState(false);
	const [isCardOpen, setIsCardOpen] = useState(false);

	const loginProgram = async (id, creatorId, touchpoint, heading) => {
		handleMarketProgramLogin(
			id,
			creatorId,
			token,
			touchpoint,
			fan,
			heading,
			dispatch,
			setLoader
		);
	};

	useEffect(() => {
		setMarketTheme();
	}, []);

	const handleButtonClick = (
		id,
		creatorId,
		touchpoint,
		heading,
		isRedeemed
	) => {
		if (!token || isRedeemed) {
			setIsCardOpen(id);
		} else {
			loginProgram(id, creatorId, touchpoint, heading);
		}
	};

	return (
		<>
			<div className="h-full body">
				<div className="container mx-auto pb-20">
					<h1 className="tracking-[0.01em] text-center text-[72px] font-semibold leading-[79px] mt-20 mb-16">
						Find Your Encore
					</h1>

					<div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5">
						{(programs || [])?.map((market, index) => {
							let heading =
								market?.config?.data?.tiers?.[0]?.after?.heading?.replace(
									"{disc.heading}",
									market?.config?.data?.tiers?.[0]?.disc?.heading
								);
							let isRedeemed = Object.keys(reedemPrograms || "").includes(
								market?.id
							);
							return (
								<div
									key={index}
									className="flex items-stretch group w-full max-w-[420px] h-[518px] mx-auto"
								>
									<div className="w-full rounded-[20px] bg-tile">
										<div className="rounded-t-[20px] overflow-hidden relative">
											{market?.assets?.landingPageImage ? (
												<img
													src={market?.assets?.landingPageImage}
													alt={market?.displayName}
													loading="eager"
													className={`max-w-full h-[420px] object-cover w-full rounded-t-[20px] transition-transform duration-500 group-hover:transition-all group-hover:duration-1000 group-hover:scale-[1.1]`}
												/>
											) : (
												<div
													className={`max-w-full h-[420px] object-cover ${
														market?.config?.theme?.["--landing-page-body"]
															? `bg-[${market.config.theme["--landing-page-body"]}]`
															: "bg-black"
													}  w-full rounded-t-[20px] transition-transform duration-500 group-hover:transition-all group-hover:duration-1000 group-hover:scale-[1.1]`}
												/>
											)}
											<AnimateCard
												open={isCardOpen}
												token={token}
												market={market}
												code={reedemPrograms?.[market?.id]?.code}
                                                isRedeemed={isRedeemed}
											/>
										</div>
										<CardFooter
											heading={heading}
											website={market?.config?.website}
											redirectButton={isRedeemed}
											loader={loader === market?.id}
											code={reedemPrograms?.[market?.id]?.code}
											store={market?.displayName}
											openCard={() =>
												handleButtonClick(
													market?.id,
													market?.creatorId,
													market?.touchpoints[0],
													heading,
													isRedeemed
												)
											}
										/>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</>
	);
};

export default MarketPlaceLanding;
