import React, { useState } from "react";
import { connect } from "react-redux";
import logo_red from "@assets/logo_red.png";
import Loader from "@components/Loader";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import NotificationService from "@services/notification-service";
import auth0 from "auth0-js";
import Api from "@services/api";

const Signin = (props: any) => {
	const { actions } = require("@redux/UserRedux");
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState("");
	const [codeError, setCodeError] = useState("");
	const [code, setCode] = useState("");
	const [step, setStep] = useState(false);
	const [loader, setLoader] = useState(false);
	const [loading, setLoading] = useState(false);
	const [verificationLoader, setVerificationLoader] = useState(false);
	const [accessTokenJwt, setAccessTokenJwt] = useState("");
	const fdata = useSelector((state: any) => Object.assign({}, state.user.form));
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const WebAuth = new auth0.WebAuth({
		clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
		audience: process.env.REACT_APP_AUTH0_AUDIENCE,
		domain: process.env.REACT_APP_AUTH0_DOMAIN,
		redirectUri: window.location.origin,
		responseType: "token id_token",
	});

	React.useEffect(() => {
		const urlFragment = window.location.hash;
		const accessTokenRegex = /access_token=([^&]+)/;
		const match = urlFragment.match(accessTokenRegex);
		const accessTokenJwt = match ? match[1] : "";

		if (accessTokenJwt) {
			setLoading(true);
		}
		setAccessTokenJwt(accessTokenJwt);
	}, []);

	const authenticate = async () => {
		Api.setClientToken(accessTokenJwt);
		const json = await actions.authenticate(
			dispatch,
			null,
			"creator",
			null,
			accessTokenJwt
		);
		if (json?.status == 200) {
      setLoading(false);
			navigate("/home");
		} else if (json?.status == 404) {
      setLoading(false);
			let form = fdata;
			form = {
				...form,
				refresh: (form?.refresh || 0) + 1,
			};
			await dispatch(actions.addForm(form));
			if (form?.refresh > 1) {
				dispatch(actions.setLogout(true));
				navigate("/logout");
				return;
			}
		}
	};

	React.useEffect(() => {
		if (accessTokenJwt) {
			authenticate();
		}
	}, [accessTokenJwt]);

	const handleEmailSubmit = async () => {
		if (!validation(email)) return;
		setLoader(true);

		const data = {
			type: "email",
			query: "sendToken",
			email: email
		};
		const res = await Api.emailAuthentication(data);
		if (res?.status == 200) {
			setEmailError("");
			setStep(true);
		} else {
			setEmailError(res?.data?.message?.[0] || "Invalid email address");
		}
		setLoader(false)
	};

	const resendHandle = async () => {
		setVerificationLoader(true);
		const data = {
			type: "email",
			query: "sendToken",
			email: email,
		};
		const res = await Api.emailAuthentication(data);
		setVerificationLoader(false);
		if (res?.status === 200) {
			NotificationService.success("A verification code has been sent to your email.")
		} else {
			NotificationService.error("An error occurred; please enter valid email.")
		}
	};

	const handleLogin = async (e) => {
		e.preventDefault();

		// Validate code input
		if (!codeValidation(code)) return;
		setLoader(true);

		const data = {
			type: "email",
			query: "verifyToken",
			email: email,
			verifyCode: code,
			encore_hosted: true,
		};
		const res = await Api.emailAuthentication(data);
		setLoader(false);
		if (res?.status === 200) {
			setAccessTokenJwt(res?.data?.jwtToken);
			setCodeError("");
		} else {
			setCodeError("Invalid verification code");
		}
	};

	const validation = (value: any) => {
		const validRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

		if (!validRegex.test(value)) {
			setEmailError("Invalid email address");
			return false;
		} else {
			setEmailError("");
			return true;
		}
	};

	const codeValidation = (value: string) => {
		const validCodeRegex = /^\d{6}$/;

		if (value.length === 0) {
			setCodeError("Verification code is required");
			return false;
		} else if (!validCodeRegex.test(value)) {
			setCodeError("Invalid verification code");
			return false;
		} else {
			setCodeError("");
			return true;
		}
	};

	return loading ? (
		<div className="flex items-center justify-center bg-[#F6F2E9] h-screen overflow-auto">
			<Loader height={30} width={30} white={false} creator={true} />
		</div>
	) : (
		<div
			className={`${
				props.step == 2
					? "lg:left-auto left-full lg:-bottom-full bottom-auto"
					: props.step == 3
					? "lg:left-auto left-full lg:invisible lg:opacity-0 transition-linear-in"
					: props.step == 4
					? "lg:bottom-0 lg:left-auto left-0"
					: "lg:bottom-full lg:left-auto -left-full"
			}  z-10 absolute w-full transition-all duration-1000 ease-in-out h-full text-center py-10 flex flex-col`}
		>
			<div
				className={`${
					props.step == 0
						? "top-0"
						: props.step == 1
						? "top-full translate-y-1/4"
						: props.step == 2
						? "lg:hidden"
						: "top-full"
				} absolute h-screen lg:block hidden transition-[top] w-full duration-[1500ms] ease-in-out bg-[#fff]/5`}
			/>
			<div className="flex flex-col justify-center mt-20">
				<img
					alt={"logo"}
					src={logo_red}
					className="h-[27px] w-[170px] object-contain mx-auto"
				/>
				<div className="max-w-[206px] w-full mx-auto mt-[31px] rounded-full overflow-hidden flex items-center">
					<div className="h-[10px] w-full bg-[#F6104E]" />
					<div className="h-full w-[1.2px] bg-[#fff]" />
					<div className="h-[10px] w-full bg-[#F6104E]/10" />
				</div>

					<div className="flex justify-center items-center flex-col w-full lg:grow-0 lg:mt-[23px] mt-[20px] lg:mb-[30px] mb-[20px]">
						<div className="max-w-[340px] w-full px-3 flex flex-col">
							<div className="flex flex-col items-center">
								<h2 className="text-black font-medium text-[24px] leading-9 mx-auto text-center">
									Welcome to Encore!
								</h2>
								<p className="text-[black]/70 font-normal text-[18px] leading-9 mx-auto text-center">
									Let’s get started, what’s your email?
								</p>
							</div>
							<div className="mt-[44px]">
								<div className="flex items-center w-full max-w-sm mx-auto gap-x-1">
									<input
										type="email"
                    disabled={step}
										onChange={(e) => setEmail(e.target.value)}
										placeholder="hello@gmail.com"
										className="bg-white w-full mx-auto h-[60px] px-3 text-center rounded-[10px] text-[18pt] placeholder:opacity-30 lg:placeholder:font-normal placeholder:font-light font-normal text-black shadow-none outline-none"
										data-testid="email"
									/>
								</div>
                {!step && (
								<div className="mt-[14px] flex items-center">
									<p className="text-[black]/70 font-normal  lg:text-[13px] text-[10px]">
										This can NOT be your agent's, business manager's, or best
										friend's email. It must be the creator's email.
									</p>
								</div>
                )}
								<div className="flex items-center">
									{emailError && (
										<p
											className="w-full max-w-xs text-red-500 font-normal lg:text-[13px] text-[10px]"
											data-testid="user_name_error"
										>
											{emailError}
										</p>
									)}
								</div>
							</div>
						</div>
						{email !== "" && !step && (
							<button
								disabled={loader}
								onClick={handleEmailSubmit}
								className="bg-[#f6104e] hover:opacity-80 rounded-full mx-auto h-[42px] w-[89px] font-medium text-[18px] text-white mt-20"
							>
								{loader ? (
									<Loader height={30} width={30} white={true} />
								) : (
									"log in"
								)}
							</button>
						)}
					</div>
				
				{step && (
					<div className="flex justify-center items-center flex-col w-full lg:grow-0 lg:mb-[70px] mb-[40px]">
						<div className="max-w-[340px] w-full px-3 flex flex-col">
							<div className="flex flex-col items-center">
								<p className="text-[black]/70 font-normal text-[18px] leading-9 mx-auto text-center">
									Please enter the six-digit code you just received via Email.
								</p>
							</div>
							<div className="mt-[10px]">
								<div className="flex items-center w-full max-w-sm mx-auto gap-x-1">
									<input
										onChange={(e) => setCode(e.target.value)}
										type="text"
										placeholder="XXXXXX"
										className="bg-white w-full mx-auto h-[60px] px-3 text-center rounded-[10px] text-[18pt] placeholder:opacity-30 lg:placeholder:font-normal placeholder:font-light font-normal text-black shadow-none outline-none"
										data-testid="code"
									/>
								</div>
								{verificationLoader ? (
										<div className="mt-2" >
										<Loader height={30} width={30} white={false} />
										</div>
									) : (
										<p
										onClick={resendHandle}
										className="font-[550] text-[16px] leading-8 text-[black]/70 text-center underline cursor-pointer mt-2"
									>
										Resend verification code
									</p>
									)}
								<div className="flex items-center">
									{codeError && (
										<p
											className="w-full max-w-xs text-red-500 font-normal lg:text-[13px] text-[10px]"
											data-testid="user_name_error"
										>
											{codeError}
										</p>
									)}
								</div>
							</div>
						</div>
						{code !== "" && (
							<button
								disabled={loader}
								onClick={handleLogin}
								className="bg-[#f6104e] hover:opacity-80 rounded-full mx-auto h-[42px] w-[100px] font-medium text-[18px] text-white mt-16"
							>
								{loader ? (
									<Loader height={30} width={30} white={true} />
								) : (
									"continue"
								)}
							</button>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = ({ user }) => {
	return {
		step: user.step,
	};
};

export default connect(mapStateToProps, undefined)(Signin);
