import { useState, FormEvent, useEffect } from "react";
import { CountryDropdown } from "./components/countryDropDown";
import { Input } from "./components/input";
import { CountryCode } from "./types";
import countries from "@helpers/countries";
import { SubmitButton } from "@components/SubmitButton";

interface PhoneInputProps {
  onSubmit?: (phone: string) => void;
  phoneNumber?: string;
  disabledInput?:boolean;
  error?: string;
  disabled?: boolean;
  buttonLabel?: string;
  isLoading?: boolean;
  backgroundColor?: string;
}

export const PhoneInput = ({
  onSubmit,
  phoneNumber,
  disabledInput,
  error,
  disabled = false,
  isLoading = false,
  buttonLabel,
  backgroundColor = "bg-tile-form",
}: PhoneInputProps) => {
  const [selectedCountry, setSelectedCountry] = useState<CountryCode>(
    countries[0]
  );
  const [phone, setPhone] = useState(() => {
    if (phoneNumber) {
      return phoneNumber;
    }
    return countries[0].callingCodes[0];
  });
  const [localError, setLocalError] = useState<null | string>(null);

  useEffect(() => {
    if (phoneNumber !== undefined) {
      setPhone(phoneNumber);
    }
  }, [phoneNumber]);

  const handleCountryChange = (data: CountryCode) => {
    if (phone.length === 0) {
      setPhone(data.callingCodes[0]);
    } else {
      const idx = phone.indexOf("+");
      if (idx >= 0) {
        const newPhone = phone.replace(
          selectedCountry.callingCodes[0],
          data.callingCodes[0]
        );
        setPhone(newPhone);
      } else {
        setPhone((prev) => data.callingCodes[0] + prev);
      }
    }
    setSelectedCountry(data);
  };

  const handlePhoneChange = (newPhone: string) => {
    const hasPlus = newPhone.length > 0 && newPhone[0] === "+";
    const onlyNumbers = newPhone.replace(/\D/g, "");
    const newNumber = `${hasPlus ? "+" : ""}${onlyNumbers}`;
    setPhone(newNumber);
    if (hasPlus) {
      const matchCountries = countries.find((country) => {
        const idx = newPhone.indexOf(country.callingCodes[0]);
        return idx >= 0;
      });
      if (matchCountries) {
        setSelectedCountry(matchCountries);
      }
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // TODO: Review if we have to send only numbers
    /*
    if (phone.length > 2) {
      const isNumeric = phone.substring(1).match(/^[0-9]+$/);
      if (isNumeric) {
        setLocalError(null);
        if (onSubmit) {
          onSubmit(phone);
        }
      } else {
        setLocalError("Phone number invalid");
      }
    }
    */
    if (onSubmit) {
      const hasPlus = phone.length > 0 && phone[0] === "+";
      const correctNumber = hasPlus
        ? phone
        : `${selectedCountry.callingCodes[0]}${phone}`;
      onSubmit(correctNumber);
    }
  };


  return (
    <div>
      <form
        className={`${backgroundColor} flex items-center justify-between rounded-[10px] relative`}
        onSubmit={(e) => handleSubmit(e)}
        autoComplete={"tel"}
      >
        <CountryDropdown
          selectedCountry={selectedCountry}
          onCountryChange={handleCountryChange}
        />
        <div
          className={`${backgroundColor} px-2 grow flex items-center justify-between rounded-[10px] pr-[16px]`}
        >
          <Input value={phone}  onChange={handlePhoneChange} disabled={disabledInput} />
          <SubmitButton
            label={buttonLabel}
            disabled={disabled || (phone && phone.length < 10 || phone.length >= 15) || phone === ""}
            isLoading={isLoading}
          />
        </div>
      </form>
      {(error || localError) && (
        <p className="font-normal text-[10px] mt-1 text-center text-red-500 ">
          {error || localError}
        </p>
      )}
    </div>
  );
};
