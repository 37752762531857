import Api from "@services/api";
import CallbackService from "@services/callback-service";
import NotificationService from "@services/notification-service";

const types = {
  FETCH_CREATOR_ASSET_PENDING: "FETCH_CREATOR_ASSET_PENDING",
  FETCH_CREATOR_ASSET_SUCCESS: "FETCH_CREATOR_ASSET_SUCCESS",
  FETCH_CREATOR_ASSET_FAILURE: "FETCH_CREATOR_ASSET_FAILURE",

  FETCH_CREATOR_DATA_PENDING: "FETCH_CREATOR_DATA_PENDING",
  FETCH_CREATOR_DATA_SUCCESS: "FETCH_CREATOR_DATA_SUCCESS",
  FETCH_CREATOR_DATA_FAILURE: "FETCH_CREATOR_DATA_FAILURE",

  SUBMIT_CREATOR_TYPE_FORM_PENDING: "SUBMIT_CREATOR_TYPE_FORM_PENDING",
  SUBMIT_CREATOR_TYPE_FORM_SUCCESS: "SUBMIT_CREATOR_TYPE_FORM_SUCCESS",
  SUBMIT_CREATOR_TYPE_FORM_FAILURE: "SUBMIT_CREATOR_TYPE_FORM_FAILURE",

  FETCH_CREATOR_TYPE_FORM_PENDING: "FETCH_CREATOR_TYPE_FORM_PENDING",
  FETCH_CREATOR_TYPE_FORM_SUCCESS: "FETCH_CREATOR_TYPE_FORM_SUCCESS",
  FETCH_CREATOR_TYPE_FORM_FAILURE: "FETCH_CREATOR_TYPE_FORM_FAILURE",

  DELETE_CREATOR_TYPE_FORM_PENDING: "DELETE_CREATOR_TYPE_FORM_PENDING",
  DELETE_CREATOR_TYPE_FORM_SUCCESS: "DELETE_CREATOR_TYPE_FORM_SUCCESS",
  DELETE_CREATOR_TYPE_FORM_FAILURE: "DELETE_CREATOR_TYPE_FORM_FAILURE",

  UPDATE_CREATOR_TYPE_FORM_PENDING: "UPDATE_CREATOR_TYPE_FORM_PENDING",
  UPDATE_CREATOR_TYPE_FORM_SUCCESS: "UPDATE_CREATOR_TYPE_FORM_SUCCESS",
  UPDATE_CREATOR_TYPE_FORM_FAILURE: "UPDATE_CREATOR_TYPE_FORM_FAILURE",

  FETCH_CREATOR_PROFILE_DATA_SUCCESS: "FETCH_CREATOR_PROFILE_DATA_SUCCESS",
  FETCH_CREATOR_PROFILE_DATA_PENDING: "FETCH_CREATOR_PROFILE_DATA_PENDING",

  FETCH_FAN_REFERRALS_SUCCESS: "FETCH_FAN_REFERRALS_SUCCESS",

  DEACTIVEATE_FAN_REFERRALS_SUCCESS: "DEACTIVEATE_FAN_REFERRALS_SUCCESS",
  DEACTIVEATE_FAN_REFERRALS_PENDING: "DEACTIVEATE_FAN_REFERRALS_PENDING",

  FETCH_CREATOR_PROGRAM_PENDING: "FETCH_CREATOR_PROGRAM_PENDING",
  FETCH_CREATOR_PROGRAM_SUCCESS: "FETCH_CREATOR_PROGRAM_SUCCESS",
  FETCH_CREATOR_PROGRAM_FAILURE: "FETCH_CREATOR_PROGRAM_FAILURE",

  SET_INVITE_TOKEN: "SET_INVITE_TOKEN",
  SET_SCANNER_EVENT_PAYLOAD: "SET_SCANNER_EVENT_PAYLOAD",

  FETCH_ACTION_ITEM_DETAIL_SUCCESS: "FETCH_ACTION_ITEM_DETAIL_SUCCESS",

  CREATOR_SET_IS_PROGRAM_REGISTRATION_END:
    "CREATOR_SET_IS_PROGRAM_REGISTRATION_END",

  ADD_FORM: "ADD_FORM",
  CLEAR_FORM: "CLEAR_FORM",

  TOGGLE_EMAIL_OPTION: "TOGGLE_EMAIL_OPTION",
  TOGGLE_PLAYER_SWITCH: "TOGGLE_PLAYER_SWITCH",
  TOGGLE_TEAM_SWITCH: "TOGGLE_TEAM_SWITCH",
  TOGGLE_SPORT_SWITCH: "TOGGLE_SPORT_SWITCH",

  SET_IS_STEP_COMPLETED:"SET_IS_STEP_COMPLETED",

  UPDATE_SOCIAL_ACTION_ITEM_IDS:"UPDATE_SOCIAL_ACTION_ITEM_IDS",

  UPDATE_EXTRA_INFO:"UPDATE_EXTRA_INFO",

  CLEAR_NOTIFICATION_TRAY:"CLEAR_NOTIFICATION_TRAY",
};
export const actions = {
  fetchCreatorAssets: async (dispatch, creator_id) => {
    const json = await Api.fetchCreatorAssets(creator_id);
    if (json !== undefined) {
      if (200 === json.status) {
        dispatch({ type: types.FETCH_CREATOR_ASSET_SUCCESS, data: json.data });
      }
    }
    return json;
  },

  fetchCreatorData: async (dispatch, filter) => {
    const json = await Api.filterByCreator(filter);
    if (json !== undefined) {
      if (200 === json.status) {
        CallbackService.setSettings(json.data?.[0]);
        dispatch({
          type: types.FETCH_CREATOR_DATA_SUCCESS,
          data: json.data?.[0],
        });
      } else if (404 === json.status) {
        dispatch({ type: types.FETCH_CREATOR_DATA_SUCCESS, data: {} });
      }
    }
    return json;
  },

  fetchProfileData: async (dispatch, data) => {
    dispatch({ type: types.FETCH_CREATOR_PROFILE_DATA_PENDING });
    const json = await Api.creatorTouchpoints(data);
    if (json !== undefined) {
      if (200 === json.status) {
        dispatch({
          type: types.FETCH_CREATOR_PROFILE_DATA_SUCCESS,
          data: json.data,
        });
      } else {
        // dispatch({
        //   type: types.FETCH_CREATOR_TYPE_FORM_FAILURE,
        //   data: json.data?.error?.message,
        // });
      }
    } else {
      // dispatch({ type: types.FETCH_CREATOR_TYPE_FORM_FAILURE, data: "" });
    }
    return json;
  },

  submitTypeForm: async (dispatch, data) => {
    dispatch({ type: types.SUBMIT_CREATOR_TYPE_FORM_PENDING });
    const json = await Api.patchCreators(`touchpoints/init/typeform`, data);
    if (json !== undefined) {
      if (200 === json.status) {
        dispatch({
          type: types.SUBMIT_CREATOR_TYPE_FORM_SUCCESS,
          data: json.data,
        });
      } else {
        NotificationService.error(json.data?.error?.message);
        dispatch({ type: types.SUBMIT_CREATOR_TYPE_FORM_FAILURE, data: "" });
      }
    } else {
      NotificationService.error("Something went wrong.");
      dispatch({ type: types.SUBMIT_CREATOR_TYPE_FORM_FAILURE, data: "" });
    }
    return json;
  },

  fetchTypeForm: async (dispatch, data) => {
    dispatch({ type: types.FETCH_CREATOR_TYPE_FORM_PENDING });
    const json = await Api.creatorTouchpoints(data);
    if (json !== undefined) {
      if (200 === json.status) {
        dispatch({
          type: types.FETCH_CREATOR_TYPE_FORM_SUCCESS,
          data: json.data,
        });
      } else {
        dispatch({
          type: types.FETCH_CREATOR_TYPE_FORM_FAILURE,
          data: json.data?.error?.message,
        });
      }
    } else {
      dispatch({ type: types.FETCH_CREATOR_TYPE_FORM_FAILURE, data: "" });
    }
    return json;
  },

  deleteTypeForm: async (dispatch, data) => {
    dispatch({ type: types.DELETE_CREATOR_TYPE_FORM_PENDING });

    const json = await Api.patchCreators(
      `touchpoints/action-items/deactivate/${data.id}`
    );
    if (json !== undefined) {
      if (200 === json.status) {
        NotificationService.success("Touchpoint deleted successfully.");
        dispatch({ type: types.DELETE_CREATOR_TYPE_FORM_SUCCESS, data: data });
      } else {
        NotificationService.error(json.data?.error?.message);
        dispatch({
          type: types.DELETE_CREATOR_TYPE_FORM_FAILURE,
          data: json.data?.error?.message,
        });
      }
    } else {
      NotificationService.error("Something went wrong.");
      dispatch({ type: types.DELETE_CREATOR_TYPE_FORM_FAILURE, data: "" });
    }
    return json;
  },

  updateTypeForm: async (dispatch, data) => {
    dispatch({ type: types.UPDATE_CREATOR_TYPE_FORM_PENDING });
    const json = await Api.patchCreators(
      `touchpoints/action-items/${data.id}`,
      data
    );
    if (json !== undefined) {
      if (200 === json.status) {
        NotificationService.success("Touchpoint updated successfully.");
        dispatch({
          type: types.UPDATE_CREATOR_TYPE_FORM_SUCCESS,
          data: json.data,
        });
      } else {
        NotificationService.error(json.data?.error?.message);
        dispatch({
          type: types.UPDATE_CREATOR_TYPE_FORM_FAILURE,
          data: json.data?.error?.message,
        });
      }
    } else {
      dispatch({ type: types.UPDATE_CREATOR_TYPE_FORM_FAILURE, data: "" });
    }
    return json;
  },

  fanReferrals: async (dispatch, touchpoint, actionItem, body) => {
    body = {
      actionItemData: {
        points: 250,
      },
    };
    const json = await Api.fanReferrals(touchpoint, actionItem, body);
    if (json !== undefined) {
      if (200 === json.status) {
        dispatch({
          type: types.FETCH_FAN_REFERRALS_SUCCESS,
          data: json.data,
        });
      }
    }
    return json;
  },

  fanReferralsDeactivate: async (dispatch, actionItemId) => {
    dispatch({ type: types.DEACTIVEATE_FAN_REFERRALS_PENDING });
    const json = await Api.deactivateTouchpointActionItem(actionItemId);
    if (json !== undefined) {
      if (200 === json.status) {
        dispatch({
          type: types.DEACTIVEATE_FAN_REFERRALS_SUCCESS,
          data: json.data,
        });
      }
    }
    return json;
  },

  fetchActionItemDetail: async (dispatch, id) => {
    if (id) {
      const json = await Api.getActionItemDetail(id);
      if (json !== undefined) {
        if (200 === json.status) {
          dispatch({
            type: types.FETCH_ACTION_ITEM_DETAIL_SUCCESS,
            data: json.data,
          });
        }
      }
    } else {
      dispatch({
        type: types.FETCH_ACTION_ITEM_DETAIL_SUCCESS,
        data: {},
      });
    }
  },

  fetchFanProgram: async (dispatch, params) => {
    dispatch({
      type: types.FETCH_CREATOR_PROGRAM_PENDING,
    });

    const json = await Api.getFanProgram(params);
    if (json !== undefined) {
      if (json.status == 200) {
        dispatch({
          type: types.FETCH_CREATOR_PROGRAM_SUCCESS,
          data: json.data?.data,
          params:params
        });
      } else {
        dispatch({
          type: types.FETCH_CREATOR_PROGRAM_FAILURE,
        });
      }
    }
    return json;
  },

  addForm: (data) => {
    return { type: types.ADD_FORM, data };
  },

  clearForm: () => {
    return { type: types.CLEAR_FORM };
  },

  emailSwitch: () => {
    return { type: types.TOGGLE_EMAIL_OPTION };
  },
  playerSwitch: () => {
    return { type: types.TOGGLE_PLAYER_SWITCH };
  },
  teamSwitch: () => {
    return { type: types.TOGGLE_TEAM_SWITCH };
  },
  sportSwitch: () => {
    return { type: types.TOGGLE_SPORT_SWITCH };
  },
  upddateExtraInfo: (data)=>{
    return {type:types.UPDATE_EXTRA_INFO, data}
  },
  clearNotificationTray:()=>{
    return {type:types.CLEAR_NOTIFICATION_TRAY}
  }
};

const initialState = {
  assets: {},
  data: {},
  form: {},
  error: "",
  isSubmitting: false,
  isLoading:true,
  profile: {
    data: [],
    isFetching: true,
  },
  creatorstouchpoint: {},
  typeForm: {
    data: [],
    isFetching: false,
    isDeleting: false,
    isSubmitting: {},
    total: 0,
  },
  inviteToken: null,
  scannerEventPayload: null,
  actionItemDetail: {},
  isRegistrationEnd: false,

  emailSwitch: true,
  playerSwitch: true,
  teamSwitch: true,
  sportSwitch: true,

  creator: {},
  program: {},
  touchpoints:[],

  isStepCompleted:{},

  socialIds:{},

  management:{}
};

export const reducer = (state = initialState, action) => {
  const { type, data, params } = action;

  switch (type) {
    case types.FETCH_CREATOR_ASSET_SUCCESS: {
      return {
        ...state,
        assets: data,
      };
    }

    case types.FETCH_CREATOR_DATA_SUCCESS: {
      return {
        ...state,
        data: data,
      };
    }

    case types.SUBMIT_CREATOR_TYPE_FORM_PENDING: {
      return {
        ...state,
        error: "",
        isSubmitting: true,
      };
    }

    case types.SUBMIT_CREATOR_TYPE_FORM_SUCCESS: {
      const typeForm = data?.[0]?.typeformActionItem || {};
      return {
        ...state,
        error: "",
        isSubmitting: false,
        form: {},
        typeForm: {
          ...state.typeForm,
          data: [...(state.typeForm?.data || []), typeForm],
          total: state.typeForm.total + 1,
        },
      };
    }

    case types.SUBMIT_CREATOR_TYPE_FORM_FAILURE: {
      return {
        ...state,
        isSubmitting: false,
        error: data,
      };
    }

    /** Fetch type form */
    case types.FETCH_CREATOR_TYPE_FORM_PENDING: {
      return {
        ...state,
        typeForm: {
          ...state.typeForm,
          isFetching: true,
        },
      };
    }

    case types.FETCH_CREATOR_PROFILE_DATA_PENDING: {
      return {
        ...state,
        profile: {
          ...state.profile,
          isFetching: true,
        },
      };
    }

    case types.FETCH_CREATOR_PROFILE_DATA_SUCCESS: {
      const profile = data.touchpoints;

      return {
        ...state,
        creatorstouchpoint: data,
        profile: {
          ...state.profile,
          data: profile,
          isFetching: false,
        },
      };
    }

    case types.FETCH_CREATOR_TYPE_FORM_SUCCESS: {
      const typeFormData = (data.touchpoints || []).filter(
        (i) => i.touchpoint?.touchpoint === "typeform"
      );
      const typeForm = typeFormData?.[0]?.actionItems || [];

      return {
        ...state,
        creatorstouchpoint: data,
        typeForm: {
          ...state.typeForm,
          data: typeForm,
          isFetching: false,
          total: typeForm.length,
        },
      };
    }

    case types.FETCH_CREATOR_TYPE_FORM_FAILURE: {
      return {
        ...state,
        typeForm: {
          ...state.typeForm,
          isFetching: false,
        },
      };
    }

    case types.DELETE_CREATOR_TYPE_FORM_SUCCESS: {
      return {
        ...state,
        typeForm: {
          ...state.typeForm,
          data: state.typeForm.data.filter((i) => i.id != data.id),
          total: state.typeForm.total - 1,
        },
      };
    }

    /** Update TypeForm */

    case types.UPDATE_CREATOR_TYPE_FORM_PENDING: {
      return {
        ...state,
        form: {},
        isSubmitting: true,
      };
    }

    case types.UPDATE_CREATOR_TYPE_FORM_SUCCESS: {
      const typeForm = [...state.typeForm.data];
      const index = typeForm.findIndex((i) => i.id == data.id);
      if (index > -1) {
        typeForm[index] = data;
      }

      return {
        ...state,
        form: {},
        isSubmitting: false,
        typeForm: {
          ...state.typeForm,
          data: typeForm,
        },
      };
    }

    case types.UPDATE_CREATOR_TYPE_FORM_FAILURE: {
      return {
        ...state,
        form: {},
        isSubmitting: false,
      };
    }

    /** End fetch type form */

    //start fetch fan referrals
    case types.FETCH_FAN_REFERRALS_SUCCESS: {
      return {
        ...state,
        assets: data,
        isLoading: false,
      };
    }

    case types.DEACTIVEATE_FAN_REFERRALS_SUCCESS: {
      return {
        ...state,
        assets: data,
      };
    }

    case types.FETCH_ACTION_ITEM_DETAIL_SUCCESS: {
      return {
        ...state,
        actionItemDetail: data,
      };
    }

    //end fetch fan referrals

    case types.ADD_FORM: {
      return { ...state, form: data };
    }

    case types.CLEAR_FORM: {
      return {
        ...state,
        isSubmitting: false,
        error: "",
        form: {},
      };
    }

    case types.SET_INVITE_TOKEN: {
      return {
        ...state,
        inviteToken: data,
      };
    }

    case types.SET_SCANNER_EVENT_PAYLOAD: {
      return {
        ...state,
        scannerEventPayload: data,
      };
    }

    case types.CREATOR_SET_IS_PROGRAM_REGISTRATION_END: {
      return {
        ...state,
        isRegistrationEnd: data,
      };
    }

    /** Creator Program */

    case types.FETCH_CREATOR_PROGRAM_PENDING: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case types.FETCH_CREATOR_PROGRAM_SUCCESS: {
      
      let steps = [];
      const stepKeys = Object.keys(data?.program?.extraInfo || {});
      stepKeys.reverse().map((item) => {
        (data?.program?.extraInfo?.[item] || []).map((step) => {
          steps.push(step);
        });
      });
      if (steps?.length > 0) {
        steps = { "step-1": [...(steps || [])] };
      }
      
      return {
        ...state,
        isLoading: false,
        creator: data?.creator,
        program: {
          ...data?.program,
          extraInfo: steps,
        },
        
        management:{
          ...state.management,
          [params.creatorUn]:{
            ...state.management?.[params.creatorUn],
            [params.programName]:{...data, program:{...data.program,extraInfo:steps }}
          }
        },
        touchpoints:(data?.touchpoints || []),
        socialIds:{}
      };
    }

    case types.FETCH_CREATOR_PROGRAM_FAILURE: {
      return {
        ...state,
        isLoading: false,
        creator: {},
        program: {},
      };
    }

    case types.TOGGLE_EMAIL_OPTION: {
      return {
        ...state,
        emailSwitch: !state.emailSwitch,
      };
    }
    case types.TOGGLE_PLAYER_SWITCH: {
      return {
        ...state,
        playerSwitch: !state.playerSwitch,
      };
    }
    case types.TOGGLE_TEAM_SWITCH: {
      return {
        ...state,
        teamSwitch: !state.teamSwitch,
      };
    }
    case types.TOGGLE_SPORT_SWITCH: {
      return {
        ...state,
        sportSwitch: !state.sportSwitch,
      };
    }

    case types.SET_IS_STEP_COMPLETED: {
      return {
        ...state,
        isStepCompleted:{
          ...state.isStepCompleted,
          [data]:true
        },
      };
    }

    case types.UPDATE_SOCIAL_ACTION_ITEM_IDS: {
      const currentTimestamp = Date.now();
      const twentyFourHoursLater = new Date(currentTimestamp - 24 * 60 * 60 * 1000);
      return {
        ...state,
        socialIds:{
          ...state.socialIds,
          [data]:twentyFourHoursLater.getTime()
        }
      };
    }

    case types.UPDATE_EXTRA_INFO:{
    
      let steps = [];
      const stepKeys = Object.keys(data || {});
      stepKeys.reverse().map((item) => {
        (data?.[item] || []).map((step) => {
          steps.push(step);
        });
      });
      if (steps?.length > 0) {
        steps = { "step-1": [...(steps || [])] };
      }
      
      return {
        ...state,
        program: {
          ...state?.program,
          extraInfo: steps,
        },
      };
    }

    case types.CLEAR_NOTIFICATION_TRAY:{
      return {
        ...state,
        isStepCompleted: {},
      };

    }

    default:
      return state;
  }
};
