import store from "@store/configureStore";
import emitter from "@services/emitter-service";
import Management from "@services/management-service";

class TouchpointsModel {
  getFilterData() {
    const management = Management.get();
    const isStepCompleted = store.getState().creator?.isStepCompleted || false;
    const notification = store.getState().user?.socketNotification || [];
    const touchpoints = store.getState().EarnReward.data || [];
    const programData = management.program?.config?.data;

    let totalItem = 0;
    if ("tiers" in (programData ?? {})) {
        totalItem = programData?.tiers?.[Object.keys(programData?.tiers)?.length - 1]?.limit || 2;
    } else {
      totalItem = programData?.limit || 2;
    }
    let usedItem = 0;
    let externalRequired = 0;
    let redirectItem = {};
    let requiredToBeCompleted = 0;
    let requiredCompleted = 0;
    touchpoints
      .filter(
        (i) => i.active && i.actions.filter((i) => i.config?.active).length > 0,
      )
      .map((item) => {
        item.actions.map((datum) => {
          //totalItem +=1;
          if (item.type == "required" && datum.config?.priority < 0) {
            requiredToBeCompleted += 1;
          }
          if (
            item.type == "required" &&
            datum.config?.priority < 0 &&
            datum?.config?.redirectUrl &&
            datum?.shares?.fanEngagement == 0
          ) {
            externalRequired += 1;
            redirectItem = { ...datum, tpid: item?.id };
          }

          if (
            item.type == "required" &&
            datum.config?.priority < 0 &&
            datum?.shares?.fanEngagement > 0
          ) {
            requiredCompleted += 1;
          }

          if (datum?.shares?.fanEngagement > 0) {
            usedItem += parseInt(datum?.shares?.fanEngagement) || 0;
          }
        });
      });

    const maxUsedItem = Math.max(0, Math.min(totalItem, usedItem));
    const progressPercentage: any = (
      (maxUsedItem / totalItem) * 100 || 0
    ).toFixed(0);
    const isRequiredCompleted =
      requiredToBeCompleted == requiredCompleted && progressPercentage == 100;


    if(usedItem > 1 && externalRequired>0){
      setTimeout(() => {
        emitter.emit("pendingNotificationPopup", {...redirectItem, isOpen:true});
      }, 1000);
      } else {
      setTimeout(() => {
        emitter.emit("pendingNotificationPopup", {...redirectItem});
      }, 1000);

      if(isRequiredCompleted && notification.length==0 && management?.program?.config?.type=="etw"){
        if(!isStepCompleted?.[management?.program?.id]){
            store.dispatch({type:"SET_IS_STEP_COMPLETED", data:management?.program?.id});
            setTimeout(() => emitter.emit("WinNotificationPopup"), 2500);

        }
      }
    }

    return {isRequiredCompleted, progressPercentage, usedItem, totalItem};
  }
}
const Touchpoints = new TouchpointsModel();

export default Touchpoints;
