import * as React from "react";
import { useSelector } from "react-redux";
import { Switch } from "@headlessui/react";
import Loader from "@components/Loader";
import Touchpoint from "@services/touchpoint-service";
import ConfigField from "./ConfigField";
import Field from "./Field";

const mergeObjectsWithAllowedKeys = (obj1, obj2, allowedKeys) => {
  const mergedObject = { ...obj1 };

  for (const key of allowedKeys) {
    if (!mergedObject.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
      mergedObject[key] = obj2[key];
    }
  }

  return mergedObject;
};

const Action: React.FC<any> = ({ item, parent }) => {
  const TouchpointKey = parent.type;
  const kitem = item.action;
  const program = useSelector(
    (state: any) => state.user?.user?.user?.programs?.[0] || {},
  );
  const [error, setError] = React.useState("");

  const form = useSelector(
    (state: any) => state.touchpoint?.form?.[kitem] || {},
  );

  const touchpoint = useSelector(
    (state: any) =>
      (state.touchpoint?.data || []).filter(
        (i) => i.type == TouchpointKey,
      )?.[0] || {},
  );

  const isActionLoading = useSelector(
    (state: any) => state.touchpoint?.isLoading?.[kitem] || false,
  );

  const updateAction = async (datum) => {
    const formData = item.forms;

    const mergedObject = { ...datum, ...form };
    setError("");
    const mergedObjectConfig = { ...datum.config, ...form };
    let config = mergeObjectsWithAllowedKeys({}, mergedObjectConfig, [
      "limit",
      "supply",
      "multiplier",
      "timeMeasurement",
      "active",
      "hashtag"
    ]);

    if (formData?.title?.enabled && (mergedObject?.title || "") == "") {
      setError("required title");
      return;
    } else if (formData?.points?.enabled && (mergedObject?.points || 0) < 1) {
      setError("Points required");
      return;
    }

    const data = {
      id: datum?.id,
      programId: program?.id,
      tpId: touchpoint?.id,
      type: TouchpointKey,
      action: kitem,
      description: mergedObject?.description,
      title: mergedObject?.title,
      points: mergedObject?.points,
      config: { limit: -1, supply: -1, ...config },
    };
    await Touchpoint.updateAction(data);
  };

  const submitAction = async () => {
    const config = {};
    const keyValuePairs = Object.entries(item.forms?.config);
    keyValuePairs.map((item: any) => {
      config[item?.[0]] = item?.[1]?.value;
    });
    const action =
      (touchpoint.actions || []).filter((i) => i.action == kitem)?.[0] || {};

    if (action?.id) {
      const data = {
        id: action?.id,
        programId: program?.id,
        tpId: touchpoint?.id,
        type: TouchpointKey,
        action: kitem,
        config: {...action?.config, active:!(action?.config?.active || false)},
      };
      await Touchpoint.updateAction(data);
    } else {
      const data = {
        programId: program?.id,
        tpId: touchpoint?.id,
        type: TouchpointKey,
        action: kitem,
        title: item?.forms?.title?.value,
        description: item?.forms?.description?.value,
        points: item?.forms?.points?.value,
        config: {...config, active:true},
      };
      await Touchpoint.submitAction(data);
    }
  };

  const addForm = (key, value) => {
    const data = Object.assign({}, form);
    data[key] = value;
    Touchpoint.addForm(kitem, data);
  };

  const action =
    (touchpoint.actions || []).filter((i) => i.action == kitem)?.[0] || {};

  const forms = Object.keys(item.forms);
  const showBtn = (Object.keys(item?.forms?.config || {}) || [])?.filter((key) => {
    return item?.forms?.config?.[key]?.enabled
  })?.length >= 1 || (forms || [])?.filter((key) => {
    return key !== 'config' ? item?.forms?.[key]?.enabled:false
  })?.length >= 1;

  return (
    <div>
      <div className="flex items-center justify-between">
        <h4 className="font-medium text-black text-[24px] leading-[31.2px]">
          {item?.title}
        </h4>
        {isActionLoading ? (
          <div className="flex items-center h-[25px] w-[50px] shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out">
            <Loader height={30} width={30} white={false} creator={true} />
          </div>
        ) : (
          <Switch
            checked={(action?.config?.active || false)}
            onChange={submitAction}
            className={`${(action?.config?.active || false) ? "bg-[#0FA81D]" : "bg-[#A6A6A6]"}
relative flex items-center h-[25px] w-[50px] shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out`}
          >
            <span
              aria-hidden="true"
              className={`${(action?.config?.active || false) ? "translate-x-7" : "translate-x-1"}
pointer-events-none inline-block h-[19px] w-[19px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
        )}
      </div>
      <p className="font-normal text-[black]/70 text-[18px] leading-[21.2px] mt-[7px] pb-[30px]">
        {item?.description}
      </p>
      {action?.config?.active && (
        <div>
          {forms.map((key, index) => {
            const actionForm = (item?.forms || {})?.[key];
            if (key !== "config") {
              if (!actionForm?.enabled) return <></>;
              return (
                <div key={index}>
                  <Field
                    config={actionForm}
                    form={form}
                    action={action}
                    actionKey={key}
                    addForm={addForm}
                  />
                </div>
              );
            } else {
              const configs = Object.keys(actionForm || {});
              return configs.map((itemKey, index) => {
                const actionFormConfig = (actionForm || {})?.[itemKey];
                if (actionFormConfig?.enabled)
                  if (["limit", "supply", "multiplier"].includes(itemKey)) {
                    return (
                      <div key={index}>
                        <ConfigField
                          config={actionFormConfig}
                          form={form}
                          action={action}
                          actionKey={itemKey}
                          addForm={addForm}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <>
                        <Field
                          config={actionFormConfig}
                          form={form}
                          action={action?.config}
                          actionKey={itemKey}
                          addForm={addForm}
                        />
                      </>
                    );
                  }
              });
            }
          })}

          {!!error && (
            <div className="mt-4">
              <p className="text-red-400">{error}</p>
            </div>
          )}

          {/* <div className="flex flex-wrap justify-start gap-3 items-center mt-[10px] "></div> */}

          {showBtn && 
            <div className="mt-[20px] flex items-center gap-x-[20px] ">
              <button
                disabled={Object.keys(form || {}).length == 0}
                onClick={() => updateAction(action)}
                className="disabled:opacity-50 border-[#000] text-[#000] w-[92px]
                        tracking-[0.02em] flex items-center justify-center bg-transparent border-[1.5px] rounded-full h-[35px] font-normal text-[16px] cursor-pointer transition-all duration-700 ease-in-out"
              >
                Update
              </button>
              
            </div>
          }
        </div>
      )}
    </div>
  );
};

export default Action;
