import CreatorAccounts from '@containers/creator/Accounts'
import creatorTouchpoints from "@common/touchpoints.json";
import Touchpoint  from "@services/touchpoint-service";
import { useSelector } from 'react-redux';
import React from 'react';
import MobileHead from "@components/Touchpoints/MobileHead";
type Props = {}

const Accounts = (props: Props) => {

    const program = useSelector((state: any) => (state.user?.user?.user?.programs?.[0] || {}));

    React.useEffect(()=>{
      
      if(program?.id){
        Touchpoint.fetch(program?.id);
      }
    }, []);
    return (
        <>
            <MobileHead title={"Accounts"} />
            <div className="bg-[#F6F2E9] lg:h-[calc(100vh-80px)] h-[calc(100vh-90px)] overflow-auto" id='darkscrollbar'>
                <div className="container mx-auto px-[25px]">
                    <div className='py-[18px]'>
                        <div className='max-w-[1120px] mx-auto'>
                            <p className="font-semibold text-2xl text-black">Accounts</p>
                            <div className='bg-[#FBFAF6] rounded-xl p-[30px_20px] mt-10'>
                                <div className="border-b pb-5 border-[#DED7C7]">
                                    <p className="font-[550] text-[15px] leading-5 tracking-[1px] text-black">Connect</p>
                                </div>
                                {creatorTouchpoints.map((item, index) => (
                                    <CreatorAccounts key={index} item={item} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Accounts