import React from "react";
import { connect } from "react-redux";
import logo_red from "@assets/logo_red.png";

interface Props {
  form: Object;
  addFormStep: (step: number) => void;
  addForm: (form: Object) => void;
}
interface State {
  name_error: boolean;
  error_message: String;
}

class Step2 extends React.Component<Props | any, State> {
  state: State = {
    name_error: false,
    error_message: "",
  };
  //step handing function
  step = (step: number) => {
    //name field validation
    if (this.validation(this.props.form.name)) {
      this.props.addFormStep(step);
    }
  };
  //store the input field value into redux for submission
  addForm = (key: any, value: any) => {
    const form = Object.assign({}, this.props.form);
    form[key] = value;
    this.props.addForm(form);
  };
  //validate the username
  validation = (value) => {
    //regular expression to validate user name

    this.setState({
      error_message:
        "Names can only have letters or numbers. Please try again.",
    });
    var re = /[^a-zA-Z0-9 ]/;
    let error = true;
    //check username not empty and also match with the regular expression
    if (value === "" || typeof value === "undefined") {
      this.setState({ name_error: true });
      error = false;
    } else if (re.test(value)) {
      this.setState({ name_error: true });
      error = false;
    } else {
      this.setState({ name_error: false });
    }
    return error;
  };
  render() {
    return (
      <div
        className={`${
          this.props.step === 2
            ? "lg:left-auto left-0 lg:top-0 top-auto "
            : this.props.step === 3
            ? "lg:left-auto left-full lg:invisible lg:opacity-0 transition-linear-in"
            : "lg:-left-full -left-full lg:-top-full top-auto transition-linear-in"
        }  absolute border w-full transition-all duration-1000 ease-in-out h-full text-center py-10 flex flex-col`}>
        <div
          className={`${
            this.props.step === 0
              ? "top-0"
              : this.props.step === 1
              ? "top-full"
              : this.props.step === 2
              ? "lg:hidden"
              : "top-full"
          } absolute h-screen lg:block hidden transition-[top] w-full duration-[1000ms] ease-in-out bg-[#000]/5`}
        />
        <div className="flex flex-col justify-center lg:grow ">
          <img
            alt={"logo_red"}
            src={logo_red}
            className="h-[27px] w-[170px] object-contain mx-auto"
          />
          <div className="max-w-[206px] w-full mx-auto lg:mt-[51px] mt-[31px] rounded-full overflow-hidden flex items-center">
            <div className="h-[10px] w-[69px] bg-[#F6104E]" />
            <div className="h-full w-[1.2px] bg-[#fff]" />
            <div className="h-[10px] w-[69px] bg-[#F6104E]" />
            <div className="h-full w-[1.2px] bg-[#fff]" />
            <div className="h-[10px] w-[69px] bg-[#F6104E]/10" />
          </div>
          <div className="flex justify-center w-full lg:grow-0 lg:mt-[53px] mt-[30px] lg:mb-[70px] mb-[40px]">
            <div className="max-w-[340px] w-full px-3 flex flex-col">
              <div className="flex items-center">
                <h2 className="text-black font-medium text-[24px] leading-9 mx-auto text-center">
                  What name should we show on your rewards page?
                </h2>
              </div>
              <div className="mt-[44px]">
                <input
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      this.step(3);
                    }
                  }}
                  type="text"
                  onChange={(e) => this.addForm("name", e.target.value)}
                  value={this.props.form.name || ""}
                  maxLength={50}
                  placeholder="Mike Sorgenfrei"
                  className="bg-white w-full mx-auto h-[60px] px-3 text-center rounded-[10px] text-[18pt] placeholder:opacity-30 lg:placeholder:font-normal placeholder:font-light font-normal text-black shadow-none outline-none"
                  data-testid="full_name"
                  name="full_name"
                />
                <div className="mt-[14px] flex items-center">
                  <p className="text-[black]/70 font-normal lg:text-[13px] text-[12px]">
                    This should be what your fans know you by. Like Beyonce. Or
                    Mike Sorgenfrei.
                  </p>
                </div>
                <div className="flex items-end h-11">
                  {this.state.name_error && (
                    <p
                      className="w-full text-red-500 font-normal lg:text-[13px] text-[12px]"
                      data-testid="user_name_error">
                      {this.state.error_message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <button
            disabled={false}
            onClick={() => this.step(3)}
            className="bg-[#f6104e] hover:opacity-80 rounded-full mx-auto h-[42px] w-[89px] font-medium text-[18px] text-white"
          >next</button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    step: user.step,
    form: user.form,
  };
};

const mergeProps = (stateProps: any, dispatchProps: any) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("@redux/UserRedux");
  return {
    ...stateProps,
    addFormStep: (step: number) => {
      dispatch(actions.addFormStep(step));
    },
    addForm: (form: Object) => {
      dispatch(actions.addForm(form));
    },
  };
};

export default connect(mapStateToProps, undefined, mergeProps)(Step2);
