import Loader from "@components/Loader";
import check from "@assets/check.png";

interface LocalForm {
    etoken: string | null;
    code: string;
}

interface CodeInputProps {
    handleLogin: () => void;
    localForm: LocalForm;
    addForm: (key: string, value: any) => void;
    loader: boolean;
  }

export const CodeInput = ({ localForm, handleLogin, addForm, loader }: CodeInputProps) => {
	return (
		<>
			<div className="px-2 mt-[5px] bg-tile-form rounded-[10px]">
				<div className="bg-tile-form px-2 flex items-center justify-between rounded">
					<input
						onChange={(e) => addForm("code", e.target.value)}
						onKeyDown={(e) => {
							if (e.keyCode === 13) {
								handleLogin();
							}
						}}
						readOnly={localForm?.etoken != null}
						type="text"
						value={localForm?.code || ""}
						className="w-full h-[40px] rounded-[10px] text-[14px] bg-transparent tile-inlay-primary disabled:opacity-100 placeholder:text-[black]/30 placeholder:text-[14px] font-normal leading-6 shadow-none outline-none"
						placeholder="Verification Code"
					/>
					{!localForm?.etoken && (
						<button
							disabled={
								localForm?.code == "" ||
								loader ||
								(localForm?.code || "").length < 6
							}
							onClick={handleLogin}
							className={`bg-btn ${
								(localForm?.code || "").length < 6 && "opacity-30"
							} flex items-center justify-center tracking-[0.06em] w-full p-[5px_12px_7px_12px] max-w-[80px] min-w-[80px] h-[25px] rounded-full btn-inlay-primary font-medium`}
						>
							{loader ? (
								<Loader height={20} width={20} white={true} />
							) : (
								"SUBMIT"
							)}
						</button>
					)}
					{localForm?.etoken && (
						<div className="bg-green rounded-full p-[4px] h-5 w-5 flex items-center justify-center z-10 overflow-hidden">
							<img
								src={check}
								alt="Check"
								className="object-contain w-full h-full"
							/>
						</div>
					)}
				</div>
			</div>
		</>
	);
};
