import Api from "@services/api";

const types = {
  SET_GLOBAL_LOADER: "SET_GLOBAL_LOADER",
  SET_APP_LOADER: "SET_APP_LOADER",
  SET_RESOLVER_PAGE: "SET_RESOLVER_PAGE",

  CHECK_CREATOR_EXIST_PENDING: "CHECK_CREATOR_EXIST_PENDING",
  CHECK_CREATOR_EXIST_SUCCESS: "CHECK_CREATOR_EXIST_SUCCESS",
  CHECK_CREATOR_EXIST_FAILURE: "CHECK_CREATOR_EXIST_FAILURE",
  SET_APP_CREATOR: "SET_APP_CREATOR",
  ADD_ASSETS_FORM: "ADD_ASSETS_FORM",

  SET_LOGIN_FLAG: "SET_LOGIN_FLAG",

  SET_GLOBAL_ERROR_MESSAGE: "SET_GLOBAL_ERROR_MESSAGE",
};
export const actions = {
  setLoader: data => {
    return { type: types.SET_GLOBAL_LOADER, data };
  },

  fetchCreator: async (dispatch, creator_id) => {
    dispatch({ type: types.CHECK_CREATOR_EXIST_PENDING });
    const json = await Api.checkCreatorUsername(creator_id);
    if (json === undefined) {
      dispatch({
        type: types.CHECK_CREATOR_EXIST_FAILURE,
        data: { message: "Can't get data from server" },
      });
    } else if (200 !== json.status) {
      dispatch({ type: types.CHECK_CREATOR_EXIST_FAILURE });
    } else {
      dispatch({ type: types.CHECK_CREATOR_EXIST_SUCCESS, data: json.data });
    }
    return json;
  },

  addAssetForm: data => {
    return { type: types.ADD_ASSETS_FORM, data };
  },

  setLoginFlow: data => {
    return { type: types.SET_LOGIN_FLAG, data };
  },
  setAppCreator: data => {
    return { type: types.SET_APP_CREATOR, data };
  },
};

const initialState = {
  appLoader:false,
  loader: false,
  resolver: false,
  creatorLoader: false,
  creatorData: {},
  assetForm: {},
  isLoginFlow: false,
  appCreator: false,
  error: "",
};

export const reducer = (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case types.SET_GLOBAL_LOADER: {
      return {
        ...state,
        loader: data,
      };
    }

    case types.SET_APP_LOADER: {
      return {
        ...state,
        appLoader: data,
      };
    }
    case types.SET_RESOLVER_PAGE: {
      return {
        ...state,
        resolver: data,
      };
    }

    case types.CHECK_CREATOR_EXIST_PENDING: {
      return {
        ...state,
        creatorLoader: true,
      };
    }

    case types.CHECK_CREATOR_EXIST_SUCCESS: {
      return {
        ...state,
        creatorLoader: false,
        creatorData: data,
      };
    }

    case types.CHECK_CREATOR_EXIST_FAILURE: {
      return {
        ...state,
        creatorLoader: false,
        creatorData: {},
      };
    }

    case types.ADD_ASSETS_FORM: {
      return {
        ...state,
        assetForm: data,
      };
    }

    case types.SET_LOGIN_FLAG: {
      return {
        ...state,
        isLoginFlow: data,
      };
    }

    case types.SET_GLOBAL_ERROR_MESSAGE: {
      return {
        ...state,
        error: data,
      };
    }
    case types.SET_APP_CREATOR: {
      return {
        ...state,
        appCreator: data,
      };
    }
    default:
      return state;
  }
};
