import Api from "@services/api";
import * as React from "react";
import Touchpoint from "@services/touchpoint-service";
import { useSelector } from "react-redux";
import CreatorTouchpoint from "@components/CreatorTouchpoints/Touchpoint";
import creatorTouchpoints from "@common/updatedTouchpoints.json";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import copy from "@assets/copy.png";

type Props = {};

const CreatorPageView: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { actions } = require("@redux/UserRedux");
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const creatorId = useSelector((store: any) => store?.user?.user?.user?.id);
  const programId = useSelector(
    (store: any) => store?.user?.user?.user?.programs?.[0]?.id
  );
  const user = useSelector((store: any) => store?.user?.user?.user);
  const profileImage = user?.programs?.[0]?.assets?.profileImage || "";
  const touchpointData = useSelector((store: any) => store.touchpoint?.data);
  const touchpoints =
    (touchpointData || []).filter(
      (i) => i.actions.length > 0 && i.active == true
    ) || [];
  const programUrl = `${user?.username}/${user?.programs?.[0]?.name || "etw"}`;
  const [url, setUrl] = React.useState(null);
  const [copied, setCopied] = React.useState(false);

  const handleLogout = () => {
    dispatch(actions.setLogout(true));
    navigate("/logout");
  };

  const program = useSelector(
    (state: any) => state.user?.user?.user?.programs?.[0] || {}
  );

  React.useEffect(() => {
    if (program?.id) {
      Touchpoint.fetch(program?.id);
    }
  }, []);
  const handleReportDownload = async () => {
    if (url) {
      if (url) {
        const a = document.createElement("a");
        a.href = url;
        a.download = "report.csv";
        a.click();
      }
      return;
    }
    const param = {
      params: {
        filterOptions: {
          reportType: "customer",
          creatorId: creatorId,
          specificProgram: programId,
        },
      },
    };
    const json = await Api.downloadReport(param);
    if (!!json && json?.status === 200) {
      setUrl(json?.data?.data);
    } else {
      console.error("No URL returned from getReport");
    }
  };

  return (
    <div>
      <button
        type="button"
        onClick={() => setOpen(!open)}
        className="inline-flex items-center p-2 mt-2 ml-8 text-sm text-gray-500 rounded-lg md:hidden focus:outline-none"
      >
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clip-rule="evenodd"
            fill-rule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>

      <aside
        id="separator-sidebar"
        className={`fixed lg:top-20 top-16 left-0 z-40 w-64 h-screen transition-transform ${
          open ? "translate-x-0" : "-translate-x-full"
        } md:translate-x-0`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-4 overflow-y-auto bg-[#FAF8F4] rounded-lg">
          <ul className="space-y-2 font-medium">
            <li>
              <button
                onClick={() => handleReportDownload()}
                className={`flex items-center p-2 text-gray-900 rounded-lg w-full ${
                  url ? "bg-gray-200" : "hover:bg-gray-100"
                } group`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m.75 12 3 3m0 0 3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                  />
                </svg>
                <span className="ml-3">
                  {url ? "Download" : "Generate"} Report
                </span>
              </button>
            </li>
          </ul>
          <ul className="pt-4 mt-4 space-y-2 font-medium border-t border-gray-200">
            <li>
              <div
                className={`flex cursor-pointer items-center p-2 text-gray-900 transition duration-75 rounded-lg bg-gray-100 group`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.042 21.672 13.684 16.6m0 0-2.51 2.225.569-9.47 5.227 7.917-3.286-.672ZM12 2.25V4.5m5.834.166-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243-1.59-1.59"
                  />
                </svg>

                <span className="ml-3">Touchpoints</span>
              </div>
              <button
                onClick={() => handleLogout()}
                className={`flex w-full items-center p-2 text-gray-900 transition duration-75 rounded-lg md:hidden group`}
              >
                <span className="ml-3 text-[#F6104E]">Log Out</span>
              </button>
            </li>
          </ul>
        </div>
      </aside>

      <div className="p-4 lg:ml-64">
        <div className="flex flex-col justify-start items-start gap-y-12">
          <div className="text-[32px] w-full font-semibold flex flex-col justify-start items-start gap-3">
            Touchpoints
            <div className="bg-[#FBFAF6] rounded-xl p-[30px_20px] w-full">
              <div>
                <h4 className="font-medium text-black text-[24px] leading-[31.2px]">
                  Encore URL
                </h4>
                <p className="font-normal text-[black]/70 text-[18px] leading-[21.2px] mt-[7px]">
                  Before you can share your URL, the required items below must
                  be done.
                </p>
              </div>
              <div className="mt-[20px] min-w-[340px] max-w-full w-full flex items-center">
                <div className="relative h-[40px] w-auto flex items-center">
                  {touchpoints.length > 0 && profileImage ? (
                    <>
                      <div className="bg-white truncate max-w-full flex items-center w-full mx-auto h-full px-3 rounded-[10px] text-black">
                        <p className="truncate flex items-center w-full mx-auto h-full lg:text-[18px] text-[18px] font-normal text-black">
                          {`https://${process.env.REACT_APP_MAIN_DOMAIN}/${programUrl}`}
                        </p>
                        <div
                          className="flex items-center justify-center pl-2 rounded-full cursor-pointer hover:opacity-70"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `https://${process.env.REACT_APP_MAIN_DOMAIN}/${programUrl}`
                            );
                            setCopied(true);
                          }}
                        >
                          <div className="flex flex-col items-center group">
                            <img
                              className="object-contain w-[18px] h-[18px]"
                              src={copy}
                              alt="copy"
                            />
                            <div className="absolute items-center hidden top-full group-hover:flex ">
                              <p className="relative font-medium text-center z-10 p-2 text-[12px] rounded-lg leading-none text-black whitespace-no-wrap bg-white w-20 shadow-lg">
                                {!copied ? "Copy" : "Copied"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="bg-white truncate max-w-full flex items-center w-full mx-auto h-full px-3 select-none pointer-events-none rounded-[10px] text-black">
                        <div className="sm:max-w-full max-w-[260px] overflow-hidden  truncate opacity-4 flex items-center mx-auto h-full lg:text-[18px] text-[18px] font-normal text-[black]/30">
                          {`https://${process.env.REACT_APP_MAIN_DOMAIN}/${programUrl}`}
                        </div>
                        <div className="flex items-center justify-center pl-3">
                          <img
                            className="object-contain h-[18px] w-[18px]"
                            src={copy}
                            alt="copy"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
			  <div className="bg-black h-[1.2px] w-full my-[20px]" />
              {creatorTouchpoints.map((item, index) => (
                <CreatorTouchpoint key={index} item={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatorPageView;
