import { Route, Routes } from "react-router-dom";
import LandingPage from "@pages/Index";
import KnownFansPage from "@pages/fans/KnownFans";
import TouchpointsPage from "@pages/creator/Touchpoints";
import Index from "@pages/creator/Index";
import Signup from "@pages/creator/Signup";
import SettingsPage from "@pages/creator/Settings";
import Accounts from "@pages/creator/Accounts";
import MarketPlaceLanding from "@pages/marketplace/MarketPlaceLanding";
import { MarketHeader } from "@components/marketHeader";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import CreatorPageView from "@pages/creator/CreatorPageView";

export const LandingRouter = ({ ContextPage }) => {
	return (
		<Routes>
			<Route
				path="/"
				element={
					<ContextPage>
						<LandingPage />
					</ContextPage>
				}
			/>
			<Route path="*" element={<></>} />
		</Routes>
	);
};

export const CreatorRouter = ({ ContextPage }) => {
	return (
		<Routes>
			<Route
				path="/touchpoints"
				element={
					// <ContextPage>
						<TouchpointsPage />
					// </ContextPage>
				}
			/>

			<Route
				path="/"
				element={
					// <ContextPage>
						<Index />
					// </ContextPage>
				}
			/>
			<Route
				path="/signin"
				element={
					<ContextPage>
						<Signup />
					</ContextPage>
				}
			/>
			<Route
				path="/signup"
				element={
					<ContextPage>
						<Signup />
					</ContextPage>
				}
			/>
			<Route
				path="/settings"
				element={
					<ContextPage>
						<SettingsPage />
					</ContextPage>
				}
			/>

			<Route
				path="/accounts"
				element={
					<ContextPage>
						<Accounts />
					</ContextPage>
				}
			/>
			<Route
				path="/home"
				element={
					<ContextPage>
						<CreatorPageView />
					</ContextPage>
				}
			/>
			<Route path="*" element={<></>} />
		</Routes>
	);
};

export const FanRouter = ({ ContextPage }) => {
	return (
		<Routes>
			
				<Route
					path="leaderboard/known-fans"
					element={
						<ContextPage>
							<KnownFansPage />
						</ContextPage>
					}
				/>
				
			<Route path="*" element={<></>} />
		</Routes>
	);
};

export const MarketPlaceRouter = ({ ContextPage }) => {
	const { actions } = require("@redux/UserRedux");
	const dispatch = useDispatch();

	useEffect(() => {
		actions.fetchPrograms(dispatch);
	}, []);
	
	return (
		<>
			<MarketHeader />
			<Routes>
					<Route
						path=""
						element={
							<ContextPage>
								<MarketPlaceLanding />
							</ContextPage>
						}
					/>
				<Route path="*" element={<></>} />
			</Routes>
		</>
	);
};