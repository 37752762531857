import React from "react";

interface InputProps {
  onChange?: (newValue: string) => void;
  value?: string;
  placeHolder?: string;
  disabled?: boolean;
}

export const Input = ({
  onChange,
  value,
  placeHolder,
  disabled = false,
}: InputProps) => {
  return (
    <input
      className={`w-full h-[40px] font-normal text-[14px] rounded-[10px] leading-6 disabled:opacity-100 bg-transparent text-primary placeholder:text-[black]/40 placeholder:text-[14px] shadow-none outline-none tile-inlay-primary`}
      onChange={(e) => onChange(e.target.value)}
      value={value || ""}
      disabled={disabled}
      name="phone_number"
      type="tel"
      id="phone_number"
      placeholder={placeHolder}
    />
  );
};
