import { useSelector } from "react-redux";

const FullName = () => {
  let data = useSelector((state: any) =>
    Object.assign({}, state.user?.user?.user)
  );
  let number = useSelector((state: any) =>
    Object.assign({}, state.user?.form?.market?.number)
  );
  function combineNumbersToString(obj) {
    let result = "";
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            result += obj[key];
        }
    }
    return result;
}
    return (
        <>
          {`${data?.displayName || data?.email || data?.phoneNumber || combineNumbersToString(number)}`}
        </>
      );  
};

export default FullName;
