import React from 'react'
import { useSelector } from 'react-redux';
import instagram from "@assets/instagram.png";
import tiktok from "@assets/landing/tiktok.png";
import twitter from "@assets/twitter_new.png";
import youtube from "@assets/youtube_red.png";
import facebook from "@assets/facebook.png";
import { handleSocialAuth } from '@helpers/socialAuth';

type Props = {}

const CreatorAccounts: React.FC<any> = ({ item }) => {

    const TouchpointKey = item.type;
    const socialIncluded = ['instagram', 'tiktok', 'x', 'youtube', 'facebook'];

    const touchpoint = useSelector(
        (state: any) =>
            (state.touchpoint?.data || []).filter(
                (i) => i.type == TouchpointKey,
            )?.[0] || {},
    );

    const socialStatus = ((touchpoint?.actions || []).filter(i => i.type == item.type && i.action == "connect")?.[0] || {});
    const connect = socialStatus?.config?.active ? true : "config" in socialStatus ? true : false;

    const imageSources = {
        instagram: instagram,
        tiktok: tiktok,
        x: twitter,
        youtube: youtube,
        facebook: facebook
    };
    const imageSource = imageSources[item.name];
    return (
        socialIncluded.includes(item.name) &&
        <div className='mt-5 flex justify-between items-center'>
            <div className='flex items-center gap-3'>
                <div className="w-8 h-8 bg-white rounded-full">
                    <img
                        src={imageSource}
                        alt={item.name}
                        className="object-contain w-full"
                    />
                </div>
                <p className='font-semibold text-[18px] leading-5 text-black'>{item.title}</p>
            </div>
            <div>
                <button
                    disabled={connect}
                    onClick={() =>
                        handleSocialAuth(item.name)
                    }
                    className={`${!connect
                        ? " bg-black"
                        : "bg-[#48B553]"
                        } rounded-[12px] font-normal p-[12px_24px] text-white`}>
                    {socialStatus?.config?.active ? "Connected" : "config" in socialStatus ? "Connect in progress" : "Connect"}
                </button>
            </div>
        </div>
    )
}

export default CreatorAccounts