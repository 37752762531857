import Api from "@services/api";

const types = {
  FETCH_LEADERBOARD_PENDING: "FETCH_LEADERBOARD_PENDING",
  FETCH_LEADERBOARD_FAILURE: "FETCH_LEADERBOARD_FAILURE",
  FETCH_LEADERBOARD_SUCCESS: "FETCH_LEADERBOARD_SUCCESS",
  FETCH_LEADERBOARD_MORE: "FETCH_LEADERBOARD_MORE",
};
export const actions = {
  fetchLeaderboards: async (dispatch, programId, params) => {
    dispatch({ type: types.FETCH_LEADERBOARD_PENDING });
    const json = await Api.leaderboard(programId, params);
    if (json === undefined) {
      dispatch({
        type: types.FETCH_LEADERBOARD_FAILURE,
        data: { message: ["Can't get data from server"] },
      });
    } else if (200 !== json.status) {
      dispatch({ type: types.FETCH_LEADERBOARD_FAILURE, data: json.data });
    } else {
        if(params.page>1){
          dispatch({
            type: types.FETCH_LEADERBOARD_MORE,
            data: json.data?.data,
          });
        } else {
          dispatch({
            type: types.FETCH_LEADERBOARD_SUCCESS,
            data: json.data?.data,
          });
        }
        
      }
    }
  };

const initialState = {
  data: [],
  total: 0,
  meta: {},
  hasMore: false,
  isFetching: false,
  error: {},
};

export const reducer = (state = initialState, action) => {
  const { type, data, nextIndex } = action;

  switch (type) {
    case types.FETCH_LEADERBOARD_PENDING: {
      return {
        ...state,
        isFetching: true,
        error: {},
      };
    }
    case types.FETCH_LEADERBOARD_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: data.message,
      };
    }
    case types.FETCH_LEADERBOARD_SUCCESS: {
      return {
        ...state,
        data: data.leaderboard,
        meta: data.meta,
        hasMore: false,
        isFetching: false,
        error: {},
      };
    }
    case types.FETCH_LEADERBOARD_MORE: {
      return {
        ...state,
        data: state.data.concat(data.leaderboard),
        meta: data.meta,
        hasMore: true,
        isFetching: false,
        error: {},
      };
    }
    default:
      return state;
  }
};
