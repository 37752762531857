import * as React from "react";
import { useSelector } from "react-redux";
import { Switch } from "@headlessui/react";
import Loader from "@components/Loader";
import QrPopUp from "@components/QrPopUp";
import instagram from "@assets/instagram.png";
import tiktok from "@assets/tiktok_logo.png";
import twitter from "@assets/twitter_new.png";
import youtube from "@assets/youtube_red.png";
import facebook from "@assets/facebook.png";
import Action from "./Action";
import List from "./List";
import Form from "./Form";
import service from "@services/touchpoint-service";
import {
  handleSocialAuth,
} from "@helpers/socialAuth";

const socialIncluded = ['instagram', 'tiktok', 'x', 'youtube', 'facebook'];

const Touchpoint: React.FC<any> = ({ item }) => {
  const TouchpointKey = item.type;
  const program = useSelector(
    (state: any) => state.user?.user?.user?.programs?.[0] || {},
  );
  const isLoading = useSelector(
    (state: any) => state.touchpoint?.isLoading?.[TouchpointKey] || false,
  );

  const form = useSelector(
    (state: any) => state.touchpoint?.form?.[TouchpointKey+TouchpointKey] || {},
  );

  const touchpoint = useSelector(
    (state: any) =>
      (state.touchpoint?.data || []).filter(
        (i) => i.type == TouchpointKey,
      )?.[0] || {},
  );

  const createOrDelete = async () => {
    if (touchpoint?.id) {
      const data = {
        id: touchpoint?.id,
        programId: program?.id,
        type: TouchpointKey,
        active: !touchpoint.active,
      };
      await service.update(data);
    } else {
      const data = {
        programId: program?.id,
        type: TouchpointKey,
        name: item.name,
        authorized: true,
        active: true,
      };
      await service.submit(data);
    }
  };

  const deleteTouchpoint = async () => {
    if (touchpoint?.id) {
      const data = {
        id: touchpoint?.id,
        programId: program?.id,
        type: TouchpointKey
      };
      await service.delete(data);
    }
  };

  const clearForm = () => {
    service.addForm(TouchpointKey+TouchpointKey, {});
  };

  const socialStatus = ((touchpoint?.actions || []).filter(i=>i.type==item.type && i.action=="connect")?.[0] || {});
  const connect = socialStatus?.config?.active?true:"config" in socialStatus?true:false;

  return (
    <div>
      <div className="flex items-center justify-between">
        <h4 className="font-medium text-black text-[24px] leading-[31.2px]">
          {item.title}
        </h4>
        {isLoading ? (
          <div className="flex items-center h-[25px] w-[50px] shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out">
            <Loader height={30} width={30} white={false} creator={true} />
          </div>
        ) : (
            !socialIncluded.includes(item.name) &&
          <Switch
            checked={touchpoint.active ? true : false}
            onChange={createOrDelete}
            className={`${touchpoint.active ? "bg-[#0FA81D]" : "bg-[#A6A6A6]"}
relative flex items-center h-[25px] w-[50px] shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out`}
          >
            <span
              aria-hidden="true"
              className={`${
                touchpoint.active ? "translate-x-7" : "translate-x-1"
              }
pointer-events-none inline-block h-[19px] w-[19px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
        )}
      </div>
      <p className="font-normal text-[black]/70 text-[18px] leading-[21.2px] mt-[7px] mb-[30px]">
        {item.description}
      </p>
      {socialIncluded.includes(item.name) &&
      <div className="flex items-center justify-between mt-[30px] mb-[30px]">
          <div className="flex items-center gap-x-3 grow">
              <div className="w-[35px] h-[35px] bg-white rounded-full p-[6px]">
                  {item.name=="instagram" &&
                   <img
                      src={instagram}
                      alt={item.name}
                      className="object-contain w-full"
                  />
                  }
                  {item.name=="youtube" &&
                   <img
                      src={youtube}
                      alt={item.name}
                      className="object-contain w-full"
                  />
                  }
                  {item.name=="tiktok" && <img
                      src={tiktok}
                      alt={item.name}
                      className="object-contain w-full"
                  />}
                  {item.name=="x" && <img
                      src={twitter}
                      alt={item.name}
                      className="object-contain w-full"
                  />}
                  {item.name=="facebook" && <img
                      src={facebook}
                      alt={item.name}
                      className="object-contain w-full"
                  />}
              </div>

              <p className="text-[#000] text-[18px] capitalize font-medium tracking-wider leading-[18px]">
                  {item.name}
              </p>
          </div>
          <button
              disabled={connect}
              onClick={() =>
                  handleSocialAuth(item.name)
              }
              className={` ${!connect
                  ? "border-[#000]/70  text-[#000]/70  h-[35px]"
                  : "border-[#44cd51] green"
                  } mx-auto tracking-[0.02em] flex pl-2 pr-2 items-center justify-center  border-[1.5px] rounded-full h-[35px] font-normal text-[16px] cursor-pointer transition-all duration-700 ease-in-out`}
          >
            {socialStatus?.config?.active?"Connected":"config" in socialStatus?"Connect in progress":"Connect"}
          </button>
          {touchpoint?.id &&
          <button
              onClick={deleteTouchpoint}
              className={`border-red-400 text-red-600 pl-2 pr-2 ml-2 mx-auto tracking-[0.02em] flex items-center justify-center  border-[1.5px] rounded-full h-[35px] font-normal text-[16px] cursor-pointer transition-all duration-700 ease-in-out`}
          >
            Disconnect
          </button>
          }

      </div>
      }
      {touchpoint?.active && (
        <>
          {(item?.actions || []).map((datum, index) => (
            <div className="pl-4 mt-4" key={index}>
              <Action key={index} item={datum} parent={item} />
              {(item?.actions || []).length - 1 != index && (
                <div className="bg-gray-400 h-[1px] w-full my-[20px]" />
              )}
            </div>
          ))}
          {(item?.actions || []).length == 0 && (
            <>
              {(touchpoint?.actions || []).map((datum, index) => (
                <div className="pl-4 mt-4" key={index}>
                  <List key={index} item={datum} parent={item} />
                  {(touchpoint?.actions || []).length - 1 != index && (
                        <div className="bg-gray-400 h-[1px] w-full my-[20px]" />
                    )}
                </div>
              ))}
              {item?.forms &&
              <Form item={{}} parent={item} />
              }
              
            </>
          )}
          
        </>
      )}

      <div className="bg-black h-[1.2px] w-full my-[40px]" />
      {form?.qrcode &&
      <QrPopUp
          type={TouchpointKey}
          click={clearForm}
        />
        }
    </div>
  );
};

export default Touchpoint;
