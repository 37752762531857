import LoginModal from "@components/MarketPlace/loginModal";
import React, { useRef } from "react";
import copy_check from "@assets/copy_check.png";
import { toast } from "react-toastify";

type Props = {
	token: string;
	open: boolean;
    isRedeemed: boolean;
	market: any;
	code: null | string;
};

const AnimateCard: React.FC<Props> = ({
	token,
	open,
	market,
	code,
    isRedeemed
}) => {
	const modalRef: any = useRef(null);
	const inputRef: any = useRef(null);

	const openModal = () => {
		modalRef.current?.open();
	};

	const copyToClipboard = () => {
		inputRef?.current.select();
		document.execCommand("copy");

		toast.success(
			<div className="flex items-center gap-x-3 text-[black] text-[14px]">
				<img
					alt="logo"
					src={copy_check}
					className="h-[20px] w-[20px] object-contain"
				/>
				Code copied!
			</div>,
			{
				icon: false,
				position: "bottom-center",
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: "light",
			}
		);
	};

	if (!token) {
		return (
			<>
				<div
					onClick={openModal}
					className={`absolute transition-transform duration-1000 h-[87px] ${
						open === market?.id ? "translate-y-0" : "translate-y-32"
					} translate-x-0 cursor-pointer transition-all duration-1000 ease-in-out flex top-auto bottom-4 left-4 right-4 p-2 items-center justify-between rounded-[10px] bg-white z-20`}
				>
					<div className="flex items-center gap-2 flex-col justify-center w-full">
						<h1 className="text-[14px] font-normal leading-4 tracking-[0.04em] uppercase opacity-50 text-black">
							Your code
						</h1>
						<div className="flex gap-1">
							<p className="underline text-[20px] leading-[22px] tracking-[0.04em] font-medium">
								Log in
							</p>{" "}
							<p className="text-[20px] leading-[22px] tracking-[0.04em] font-medium">
								to reveal
							</p>
						</div>
					</div>
				</div>
				<LoginModal ref={modalRef} />
			</>
		);
	}
	return (
		<div
			className={`absolute transition-transform duration-1000 h-[87px] ${
				isRedeemed ? "translate-y-0" : "translate-y-32"
			} translate-x-0 transition-all duration-1000 ease-in-out flex top-auto bottom-4 left-4 right-4 p-2 items-center justify-between rounded-[10px] bg-white cursor-pointer z-20`}
		>
			<div className="flex items-center gap-2 flex-col justify-center w-full">
				<h1 className="text-[14px] font-normal leading-4 tracking-[0.04em] uppercase opacity-50 text-black">
					code for first time customers
				</h1>
				<div className="flex justify-center items-center">
					<h6 className="font-[500] text-[20px] tracking-[0.04em] leading-[22px] tile-inlay-primary text-center pr-3">
						{code}
					</h6>
					<div className="cursor-pointer" onClick={copyToClipboard}>
						<svg
							width="21"
							height="21"
							className="copy-icon-color"
							viewBox="0 0 21 21"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M16.6667 17.1667H8.5C8.22386 17.1667 8 16.9428 8 16.6667V8.5C8 8.22386 8.22386 8 8.5 8H16.6667C16.9428 8 17.1667 8.22386 17.1667 8.5V16.6667C17.1667 16.9428 16.9428 17.1667 16.6667 17.1667Z"
								stroke="currentColor"
								strokeWidth="1.25"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M12.9997 7.99967V4.33301C12.9997 4.05687 12.7758 3.83301 12.4997 3.83301H4.33301C4.05687 3.83301 3.83301 4.05687 3.83301 4.33301V12.4997C3.83301 12.7758 4.05687 12.9997 4.33301 12.9997H7.99967"
								stroke="currentColor"
								strokeWidth="1.25"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>
					<input
						ref={inputRef}
						value={code}
						className="absolute left-[-9999px]"
						readOnly
					/>
				</div>
			</div>
		</div>
	);
};

export default AnimateCard;
