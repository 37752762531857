import logoWhite from "@assets/logo_white.png";
import { useRef, useState } from "react";
import LoginModal from "@components/MarketPlace/loginModal";
import { useSelector } from "react-redux";
import MobileHeadETW from "@components/EnterToWin/MobileHead";

export const MarketHeader = () => {
	const modalRef: any = useRef(null);
	const token = useSelector((state: any) => state.user.token);
	const [modalOpen, setModalOpen] = useState(false);

	const openModal = () => {
		modalRef.current?.open();
	};

	return (
		<div>
			<div className="w-full bg-black h-20 flex items-center relative">
				<div className="flex justify-between grow items-center h-[70px] sm:px-7 px-4">
					<div className="flex items-center justify-start">
						<img
							src={logoWhite}
							className="sm:max-h-[30px] max-h-[24px] h-full"
						/>
					</div>
					{!modalOpen ? (
						<button
							onClick={() => (token ? setModalOpen(!modalOpen) : openModal())}
							className={`bg-white rounded-full p-[10px_24px_12px_24px] text-[16px] leading-6 tracking-[0.03em] font-semibold`}
						>
							{token ? "My Account" : "Log In"}
						</button>
					) : (
						<button 
						onClick={() => setModalOpen(!modalOpen)}
						className={"flex items-center outline-none hover:opacity-80"}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth={1.5}
								stroke="white"
								className="h-8 body-inlay-primary"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					)}
				</div>
			</div>
			<div
				className="relative h-full bg-body"
				onBlur={() => setModalOpen(false)}
			>
				<MobileHeadETW
					backpack={true}
					modalOpen={modalOpen}
					setModalOpen={setModalOpen}
				/>
			</div>
			<LoginModal ref={modalRef} />
		</div>
	);
};
