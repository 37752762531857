import Api from "@services/api";

const types = {
  FETCH_REDEEM_REWARDS_PENDING: "FETCH_REDEEM_REWARDS_PENDING",
  FETCH_REDEEM_REWARDS_FALIURE: "FETCH_REDEEM_REWARDS_FALIURE",
  FETCH_REDEEM_REWARDS_SUCCESS: "FETCH_REDEEM_REWARDS_SUCCESS",
};
export const redeemRewardActions = {
  fetchRedeemRewards: async (dispatch, programId) => {
    dispatch({ type: types.FETCH_REDEEM_REWARDS_PENDING });
    const json = await Api.fanRedeem(programId);
    if (json === undefined) {
      dispatch({
        type: types.FETCH_REDEEM_REWARDS_FALIURE,
        data: { message: ["Can't get data from server"] },
      });
    } else if (200 !== json.status) {
      dispatch({ type: types.FETCH_REDEEM_REWARDS_FALIURE, data: json.data });
    } else {
      dispatch({ type: types.FETCH_REDEEM_REWARDS_SUCCESS, data: json.data?.data });
    }
  },
};

const initialState = {
  data: [],
  isFetching: false,
  error: {},
};

export const reducer = (state = initialState, action) => {
  const { type, token, user, data } = action;

  switch (type) {
    case types.FETCH_REDEEM_REWARDS_PENDING: {
      return {
        ...state,
        isFetching: true,
        error: {},
      };
    }
    case types.FETCH_REDEEM_REWARDS_FALIURE: {
      return {
        ...state,
        isFetching: false,
        error: data,
      };
    }
    case types.FETCH_REDEEM_REWARDS_SUCCESS: {
      return { ...state, data: data, isFetching: false };
    }
    default:
      return state;
  }
};
