import React from "react";
import { connect } from "react-redux";
import logo_red from "@assets/logo_red.png";
import Loader from "@components/Loader";
import Api from "@services/api";
import Touchpoint  from "@services/touchpoint-service";


interface Props {
  form: Object;
  token:any;
  addFormStep: (step: number) => void;
  addForm: (form: Object) => void;

}
interface State {
  name_error: boolean;
  error_message: String;
  loader: boolean;
}

class Step3 extends React.Component<Props | any, State> {
  state: State = {
    name_error: false,
    error_message: "",
    loader: false,
  };
  //step handing function
  step = async (step: number) => {
    //name field validation
    if (this.validation(this.props.form.username)) {
      this.setState({ loader: true, error_message: "" });
      // const json = this.props.form.username;
      const json = await Api.checkCreatorUsername(
        this.props.form.username.toLowerCase()
      );
      if (json !== undefined) {
        if (!json.data.exists) {
        if (json.status !== 404) {
            const userData = {
              extraInfo:{
                name:this.props.form.name,
              },
              username: (this.props.form.username || "").trim().replace(/\s+/g, "-"),
            };
            const json = await this.props.doCreatorSignup(userData, this.props.token);
            if(json!=undefined){
              if(json.status==201){

                const data = {
                  creatorId:json.data?.data?.id,
                  name:"etw",
                  displayName:"etw",
                  config: {
                    type: "etw"
                  }
                }
                const jsonP = await Api.submitProgram(data);
                if(jsonP?.status==201){
                  let user = this.props.user;
                  user = {
                    ...user,
                    user:{
                      ...user.user,
                      programs:[{id:jsonP.data?.data?.programId}]
                    }
                  }
                  this.props.updateCreatorData(user);
                  Touchpoint.fetch(jsonP.data?.data?.programId);
                }
                
              } else {
                this.props.logout();
              }
            }
          } else {
            this.setState({
              error_message: "Username already exists",
            });
          }
        }
      }
      this.setState({ loader: false });
    }
  };
  //store the input field value into redux for submission
  addForm = (key: any, value: any) => {
    const form = Object.assign({}, this.props.form);
    form[key] = value.toLowerCase().replace(/\s+/g, "-");
    this.props.addForm(form);
  };
  //validate the username
  validation = (value) => {
    this.setState({
      error_message: "Username can only have letters, space, and numbers",
    });
    // var re = /[^a-zA-Z0-9]/;
    var re = /[^a-z\d\-]/gi;

    let error = true;
    //check username not empty and also match with the regular expression
    if (value === "" || typeof value === "undefined") {
      this.setState({ name_error: true });
      error = false;
    } else if (re.test(value)) {
      this.setState({ name_error: true });
      error = false;
    } else if (value === "") {
      this.setState({ error_message: "Enter your username" });
      this.setState({ name_error: true });
      error = false;
    } else if ((value || "").length > 50) {
      this.setState({ error_message: "Enter 16 characters only" });
      this.setState({ name_error: true });
      error = false;
    } else {
      this.setState({ name_error: false });
    }
    return error;
  };
  render() {
    return (
      <div
        className={`${
          this.props.step === 3
            ? "lg:left-auto left-0 lg:visible lg:opacity-100 transition-linear-in"
            : "lg:opacity-0 lg:invisible lg:left-auto -left-full  transition-linear-in"
        }  absolute w-full transition-all duration-1000 ease-in-out h-full text-center py-10 flex flex-col`}>
        <div className="flex flex-col justify-center lg:grow ">
          <img
            alt={"logo_red"}
            src={logo_red}
            className="h-[27px] w-[170px] object-contain mx-auto"
          />
          <div className="max-w-[206px] w-full mx-auto lg:mt-[51px] mt-[31px] rounded-full overflow-hidden flex items-center">
            <div className="h-[10px] w-full bg-[#F6104E]" />
            <div className="h-full w-[2px] bg-[#fff]" />
            <div className="h-[10px] w-full bg-[#F6104E]" />
          </div>
          <div className="flex justify-center w-full lg:grow-0 lg:mt-[53px] mt-[30px] lg:mb-[50px] mb-[40px]">
            <div className="max-w-[500px] w-full px-3 flex flex-col">
              <div className="flex items-center flex-col gap-4">
                <h2 className="text-black font-semibold lg:text-[32px] text-[18px] lg:leading-9 leading-8 mx-auto text-center">
                  What unique username should we use for your URL?
                </h2>
                <p className="font-normal text-[24px] leading-8 text-black">Please use letters or numbers only. We recommend using something your fans will recognize you by.</p>
              </div>
              <div className="mt-[44px]">
                <div className="flex items-center gap-x-1">
                  <input
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        this.step(4);
                      }
                    }}
                    type="text"
                    onChange={(e) =>
                      this.addForm("username", e.target.value || "")
                    }
                    value={this.props.form.username || ""}
                    maxLength={50}
                    placeholder="username.encore.fans"
                    className="bg-white lowercase w-full mx-auto h-[60px] px-3 text-center rounded-[10px] text-[18pt] placeholder:opacity-30 lg:placeholder:font-normal placeholder:font-light font-normal text-black shadow-none outline-none"
                    data-testid="username"
                    name="username"
                  />
                </div>
                <div className="mt-[10px] flex items-center justify-center">
                  <p className="text-[black]/80 font-normal lg:text-[13px] text-[12px]">
                    No special characters allowed.
                  </p>
                </div>
                <div className="flex items-end h-11">
                  {!!this.state.error_message && (
                    <p
                      className="w-full text-red-500 font-normal lg:text-[13px] text-[12px]"
                      data-testid="user_name_error">
                      {this.state.error_message}
                    </p>
                  )}
                  {!!this.props.error?.message && (
                    <p
                      className="w-full max-w-xs text-sm font-normal text-red-500"
                      data-testid="user_name_error">
                      {this.props.error?.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <button
            disabled={this.props.isFetching}
            onClick={() => this.step(4)}
            className="bg-[#f6104e] hover:opacity-80 rounded-full mx-auto h-[42px] w-[89px] font-medium text-[18px] text-white">
            {this.props.isFetching ? (
              <Loader height={30} width={30} white={true} />
            ) : (
              "confirm"
            )}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ user }, ownProps) => {
  return {
    step: user.step,
    form: user.form,
    isFetching: user.isFetching,
    error: user.error,
    user: user.user,
    token:user.creatorToken
  };
};

const mergeProps = (stateProps: any, dispatchProps: any) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("@redux/UserRedux");
  return {
    ...stateProps,
    addFormStep: (step: number) => {
      dispatch(actions.addFormStep(step));
    },
    addForm: (form: Object) => {
      dispatch(actions.addForm(form));
    },
    doMagicSignup: (data) => {
      return actions.doCreatorMagicSignup(dispatch, data);
    },
    doCreatorSignup:(data, token)=>{
      return actions.doCreatorSignup(dispatch, data, token);
    },
    updateCreatorData:(data)=>{
      return dispatch(actions.updateCreatorData(data));
    }
  };
};

export default connect(mapStateToProps, undefined, mergeProps)(Step3);
