import "./index.css";
import logo from "@assets/logo_white.png";
import user_1 from "@assets/landing/user_1.png";
import reaction from "@assets/landing/reaction.png";
import insta from "@assets/landing/insta.png";
import shopify from "@assets/landing/shopify.png";
import spotify from "@assets/landing/spotify.png";
import tiktok from "@assets/landing/tiktok.png";
import twitch from "@assets/landing/twitch.png";
import youtube from "@assets/landing/youtube.png";
import tumbler from "@assets/landing/tumbler.png";
import fanatics from "@assets/landing/fanatics.png";
import music from "@assets/landing/music.png";
import filter from "@assets/landing/filter.png";
import colors_logo from "@assets/landing/colors_logo.png";
import web3 from "@assets/landing/web3.png";
import web3_1 from "@assets/landing/web3_1.png";
import e_logo from "@assets/landing/e_logo.png";
import avatar from "@assets/landing/avatar.png";
import basket_ball from "@assets/landing/basket_ball.png";
import arrows_line from "@assets/landing/arrows_line.png";
import arrows_line_down from "@assets/landing/arrows_line_down.png";
import arrows_dot_down from "@assets/landing/arrows_dot_down.png";
import new_brand from "@assets/landing/new_brand.png";
import arrows_dot from "@assets/landing/arrows_dot.png";
import arrows_forward from "@assets/landing/arrows_forward.png";
import arrows_downward from "@assets/landing/arrows_downward.png";
import scramble_down from "@assets/landing/scramble_down.png";
import scramble from "@assets/landing/scramble.png";
import scoringd from "@assets/landing/scoringd.gif";
import { Link } from "react-router-dom";
import Logo from "@components/Logo";
import Tilt from "react-parallax-tilt";
import common from "@common/common";
import Loader from "@components/Loader";

const Start = () => {
  if(process.env.REACT_APP_LANDING_WEBSITE){
    window.location.href = process.env.REACT_APP_LANDING_WEBSITE;
    return <div className="flex justify-center items-center h-screen">
    <Loader
      height={30}
      width={30}
      color={"#000"}
    /></div>
  }
    return (
      <div className={`absolute grow w-full transition-all duration-1000 ease-in-out h-full flex flex-col`}>
        <div>
          <section className="bg-[#1D1D1D] min-h-[600px] py-[61px]">
            <div className="container mx-auto max-w-[1203px] px-[15px]">
              <div>
                <Link to="/">
                  <img
                    alt="logo"
                    src={logo}
                    className="w-[167.47px] h-[26.28px] object-contain"
                    data-testid="index"
                  />
                </Link>
              </div>
              <div className="grid xl:grid-cols-2 grid-col-1 gap-4 xl:mt-[115px] mt-[58px]">
                <div className="my-auto">
                  <h1 className="text-white lg:text-[60px] text-[32px] font-semibold lg:leading-[70px] leading-[35.2px] flex items-center flex-wrap">
                    Your
                    <div className="scroll-animation lg:h-[78px] h-[47px] relative  overflow-hidden ml-[12px] lg:space-y-[15px] space-y-[10px]">
                      <div className="h-full table bg-[#F6104E] rounded-[15px] px-[10px] lex">
                        <span className="text-white table-cell align-middle lg:text-[60px] text-[32px] font-semibold lg:leading-[66px] leading-[35.2px]">
                          email address
                        </span>
                      </div>
                      <div className="h-full table bg-[#F6104E] rounded-[15px] px-[10px] lex">
                        <span className="text-white table-cell align-middle lg:text-[60px] text-[32px] font-semibold lg:leading-[66px] leading-[35.2px]">
                          phone number
                        </span>
                      </div>
                      <div className="h-full table bg-[#F6104E] rounded-[15px] px-[10px] lex">
                        <span className="text-white table-cell align-middle lg:text-[60px] text-[32px] font-semibold lg:leading-[66px] leading-[35.2px]">
                          handle
                        </span>
                      </div>
                      <div className="h-full table bg-[#F6104E] rounded-[15px] px-[10px] lex">
                        <span className="text-white table-cell align-middle lg:text-[60px] text-[32px] font-semibold lg:leading-[66px] leading-[35.2px]">
                          location
                        </span>
                      </div>
                      <div className="h-full table bg-[#F6104E] rounded-[15px] px-[10px] lex">
                        <span className="text-white table-cell align-middle lg:text-[60px] text-[32px] font-semibold lg:leading-[66px] leading-[35.2px]">
                          fandom
                        </span>
                      </div>
                      <div className="h-full table bg-[#F6104E] rounded-[15px] px-[10px] lex">
                        <span className="text-white table-cell align-middle lg:text-[60px] text-[32px] font-semibold lg:leading-[66px] leading-[35.2px]">
                          sports team
                        </span>
                      </div>
                      <div className="h-full table bg-[#F6104E] rounded-[15px] px-[10px] lex">
                        <span className="text-white table-cell align-middle lg:text-[60px] text-[32px] font-semibold lg:leading-[66px] leading-[35.2px]">
                          identity
                        </span>
                      </div>
                    </div>
                  </h1>
                  <h1 className="text-white mt-[5px] lg:text-[60px] text-[32px] font-semibold lg:leading-[70px] leading-[35.2px] flex items-center flex-wrap">
                    is an asset.
                  </h1>
                  <p className="text-[#F6F2E9] lg:text-[32px] text-[24px] lg:leading-[35.2px] leading-[26.4px] font-normal lg:mt-[40px] mt-[20px]">
                    Get rewarded for it.
                  </p>
                </div>
                <div className="xl:mt-0 mt-[56px] xl:ml-auto ml-auto xl:mr-0 mr-auto w-full flex lg:max-w-[492.27px] max-w-[383.03px] lg:h-[350.36px] h-[250.36px]">
                  <div className="rotate-[8deg]  w-full lg:max-w-[392.27px] max-w-[283.03px] xl:ml-auto ml-auto mr-auto xl:mr-auto my-auto">
                    <Tilt tiltReverse={true} transitionSpeed={1500}>
                      <div className="lg:h-[250.36px] h-[180.64px] mx-auto w-full cursor-pointer border-[1.5px] border-[white]/30 p-[15px] bg-gradient-to-r from-[#111111] to-[#000000] rounded-[17.306px]">
                        <div className="flex lg:items-center justify-between lg:mb-[18px] mb-[14px]">
                          <div>
                            <p className="text-[#fff]/50 font-normal lg:text-[16.1522px] text-[11.65px] uppercase tracking-wider">
                              FAN ID
                            </p>
                            <p className="text-white font-normal lg:text-[20.7672px] lg:leading-[20.77px] text-[14.98px] leading-[14.98px]">
                              0126
                            </p>
                          </div>
                          <Logo
                            logo_letter="true"
                            className="lg:max-h-[63px] max-h-[45.78px] h-full w-[auto] object-contain rounded-full"
                          />
                        </div>
                        <p className="text-[#fff]/50 font-normal lg:text-[16.1522px] text-[11.65px] leading-[11.65px] lg:leading-[16.15px] uppercase tracking-wider">
                          FAN SCORE
                        </p>
                        <h1 className="font-semibold lg:text-[69.2239px] text-[49.95px] text-[#FFCAB0] lg:leading-[69.22px] leading-[49.95px] tracking-[0.02em]">
                          2,930
                        </h1>
                        <h4 className="text-white font-normal lg:text-[20.7672px] text-[14.98px] leading-[14.98px] lg:leading-[20.77px] xl:mt-5 mt-2">
                          Jane Miller
                        </h4>
                      </div>
                    </Tilt>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="bg-[#F6F2E9]">
            <div className="container mx-auto max-w-[1203px] px-[15px]">
              <div>
                <h2 className="xl:text-[35px] text-[24px]  xl:leading-[38.5px] leading-[28.8px] text-center text-black font-semibold xl:pt-[120px] pt-[80px]">
                  Your data fuels a trillion dollar industry.
                </h2>
                <p className="xl:text-[24px] text-[18px]  xl:leading-[140%] leading-[25.2px] font-normal text-black text-center mt-[15px]">
                  Still, the creators you love often struggle to effectively
                  find and communicate with you.
                </p>
                <div className="grid lg:grid-cols-7 grid-cols-1 xl:mt-[60px] mt-[50px] gap-x-[20px]">
                  <div className="flex flex-col lg:col-span-2">
                    <div className="bg-[#EEE8DB] rounded-[10px] px-[12px] py-[10px] lg:max-w-auto max-w-[500px] mx-auto">
                      <p className="tracking-[0.04em] xl:text-[18px] text-[16px] text-black font-semibold text-center">
                        Fans engage with creators they love on various
                        platforms.
                      </p>
                    </div>
                    <div className="relative flex flex-col items-center lg:flex-row lg:grow">
                      <img
                        alt="avatar"
                        src={avatar}
                        className="w-[150px] h-[150px] xl:mt-[40px] mt-[30px] object-contain xl:mr-auto mx-auto lg:ml-10"
                      />
                      <img
                        alt="arrows"
                        src={arrows_line}
                        className="max-w-[138px] lg:block hidden w-full lg:h-[360px] h-full object-contain mx-auto absolute -right-[40px] top-[60px]"
                      />
                      <img
                        alt="arrows"
                        src={arrows_line_down}
                        className="block object-contain max-w-[324px] mx-auto w-full lg:hidden mt-[10px] mb-[30px]"
                      />
                    </div>
                  </div>
                  <div className="lg:col-span-3">
                    <div className="bg-[#EEE8DB] rounded-[10px] xl:px-[12px] px-[16px] py-[10px] lg:max-w-[100%] max-w-[500px] mx-auto">
                      <p className="tracking-[0.04em] xl:text-[18px] text-[16px] text-black font-semibold text-center">
                        Platforms monetize fan data by aggregating, anonymizing,
                        and selling it to creators.
                      </p>
                    </div>
                    <div className="flex lg:flex-row flex-col items-center justify-center mt-[30px] gap-x-[30px] mx-auto">
                      <div className="flex lg:flex-col flex-row flex-wrap lg:flex-nowrap lg:gap-x-0 gap-x-[30px] items-center  lg:gap-y-[22px] gap-y-[32px] lg:max-w-auto max-w-[324px]  ">
                        <div className="flex items-center justify-between lg:flex-col gap-x-[20px] sm:gap-x-[30px] lg:gap-y-[22px] gap-y-[32px] ">
                          <div className="flex items-center gap-x-[20px] lg:flex-row flex-col lg:space-y-[0] space-y-[15px]">
                            <p className="text-[#000000]/50 text-[15px] leading-[15px] font-medium uppercase lg:w-[80px] text-right">
                              SOCIAL
                            </p>
                            <div className="flex items-center gap-x-[10px]">
                              <img
                                alt="insta"
                                src={insta}
                                className="w-[40px] h-[40px] object-contain"
                              />
                              <img
                                alt="tiktok"
                                src={tiktok}
                                className="w-[40px] h-[40px] object-contain"
                              />
                            </div>
                          </div>
                          <div className="flex items-center gap-x-[20px] lg:flex-row flex-col lg:space-y-[0] space-y-[15px]">
                            <p className="text-[#000000]/50 text-[15px] leading-[15px] font-medium uppercase lg:w-[80px] text-right">
                              CONTENT
                            </p>
                            <div className="flex items-center gap-x-[10px]">
                              <img
                                alt="youtube"
                                src={youtube}
                                className="w-[40px] h-[40px] object-contain"
                              />
                              <img
                                alt="twitch"
                                src={twitch}
                                className="w-[40px] h-[40px] object-contain"
                              />
                            </div>
                          </div>
                          <div className="flex items-center gap-x-[20px] lg:flex-row flex-col lg:space-y-[0] space-y-[15px]">
                            <p className="text-[#000000]/50 text-[15px] leading-[15px] font-medium uppercase lg:w-[80px] text-right">
                              MERCH
                            </p>
                            <div className="flex items-center gap-x-[10px]">
                              <img
                                alt="shopify"
                                src={shopify}
                                className="w-[40px] h-[40px] object-contain"
                              />
                              <img
                                alt="fanatics"
                                src={fanatics}
                                className="w-[40px] h-[40px] object-contain"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center justify-center lg:flex-col lg:gap-x-[0px] gap-x-[20px] sm:gap-x-[30px] w-full lg:gap-y-[22px] gap-y-[32px] ">
                          <div className="flex items-center gap-x-[20px] lg:flex-row flex-col lg:space-y-[0] space-y-[15px]">
                            <p className="text-[#000000]/50 text-[15px] leading-[15px] font-medium uppercase lg:w-[80px] text-right">
                              MUSIC
                            </p>
                            <div className="flex items-center gap-x-[10px]">
                              <img
                                alt="spotify"
                                src={spotify}
                                className="w-[40px] h-[40px] object-contain"
                              />
                              <img
                                alt="music"
                                src={music}
                                className="w-[40px] h-[40px] object-contain"
                              />
                            </div>
                          </div>
                          <div className="flex items-center gap-x-[20px] lg:flex-row flex-col lg:space-y-[0] space-y-[15px]">
                            <p className="text-[#000000]/50 text-[15px] leading-[15px] font-medium uppercase lg:w-[80px] text-right">
                              MEMBERS
                            </p>
                            <div className="flex items-center gap-x-[10px]">
                              <img
                                alt="filter"
                                src={filter}
                                className="w-[40px] h-[40px] object-contain"
                              />
                              <img
                                alt="colors"
                                src={colors_logo}
                                className="w-[40px] h-[40px] object-contain"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center justify-center lg:flex-col lg:gap-x-[0px] gap-x-[20px] sm:gap-x-[30px] w-full lg:gap-y-[22px] gap-y-[32px] ">
                          <div className="flex items-center gap-x-[20px] lg:flex-row flex-col lg:space-y-[0] space-y-[15px]">
                            <p className="text-[#000000]/50 text-[15px] leading-[15px] font-medium uppercase lg:w-[80px] text-right">
                              WEB3
                            </p>
                            <div className="flex items-center gap-x-[10px]">
                              <img
                                alt="web3"
                                src={web3}
                                className="w-[45px] h-[40px] object-contain"
                              />
                              <img
                                alt="web3"
                                src={web3_1}
                                className="w-[40px] h-[40px] object-contain"
                              />
                            </div>
                          </div>
                          <div className="flex items-center gap-x-[20px] lg:flex-row flex-col lg:space-y-[0] space-y-[15px]">
                            <p className="text-[#000000]/50 text-[15px] leading-[15px] font-medium uppercase lg:w-[80px] text-right">
                              TICKETS
                            </p>
                            <div className="flex items-center gap-x-[10px]">
                              <img
                                alt="tumbler"
                                src={tumbler}
                                className="w-[40px] h-[40px] object-contain"
                              />
                              <img
                                alt="tumbler"
                                src={e_logo}
                                className="w-[40px] h-[40px] object-contain"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <img
                        alt="arrows_forward"
                        src={arrows_forward}
                        className="max-w-[93px] lg:block hidden w-full h-[371.75px] object-contain "
                      />
                      <img
                        alt="arrows_downward"
                        src={arrows_downward}
                        className="max-w-[350px] lg:hidden w-full h-full mt-[30px] mx-auto max-h-[282px] object-contain"
                      />
                      <img
                        alt="scramble"
                        src={scramble}
                        className="max-w-[40px] lg:block hidden w-full h-[412px] object-contain "
                      />
                    </div>
                  </div>
                  <div className="flex flex-col lg:col-span-2">
                    <div className="bg-[#EEE8DB] rounded-[10px]  lg:my-0 my-[30px] px-[12px] py-[10px] lg:max-w-auto max-w-[500px] w-full mx-auto">
                      <p className="tracking-[0.04em] xl:text-[18px] text-[16px] text-black font-semibold text-center">
                        Creators receive generalized data, siloed by each
                        platform.
                      </p>
                    </div>
                    <img
                      alt="scramble_down"
                      src={scramble_down}
                      className="max-w-[350px] lg:hidden w-full h-full mx-auto mb-[30px] max-h-[282px] object-contain"
                    />
                    <div className="relative flex flex-col items-center lg:flex-row grow">
                      <img
                        alt="arrows"
                        src={arrows_dot}
                        className="max-w-[165px] lg:block hidden w-full h-[360px] object-contain mx-auto absolute -left-[35px] top-[60px]"
                      />
                      <img
                        alt="arrows"
                        src={arrows_dot_down}
                        className="block object-contain max-w-[324px] sm:w-full lg:hidden mx-auto w-full"
                      />
                      <img
                        alt="brands"
                        src={basket_ball}
                        className="w-[150px] h-[150px] lg:mt-[40px] mt-[30px] object-contain lg:mr-3 lg:ml-auto ml-auto mr-auto"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h2 className="xl:text-[35px] text-[24px]  xl:leading-[38.5px] leading-[28.8px] text-center text-black font-semibold xl:pt-[120px] pt-[80px]">
                  What if creators could get to know you directly?
                </h2>
                <p className="xl:text-[24px] text-[18px]  xl:leading-[140%] leading-[25.2px] font-normal text-black text-center mt-[15px]">
                  With Encore you choose what you share, and get rewarded for
                  sharing.
                </p>

                <div className="mt-[50px] max-w-[591px] w-full xl:h-[497px] h-[264.4px] mx-auto bg-white xl:rounded-[10px] rounded-[5.48223px] xl:p-[30px] p-[15px] shadow-[5px_3px_40px_rgba(222,215,199,0.3)]">
                  <div className="flex items-end gap-x-[16px] ">
                    <img
                      alt="brand"
                      src={new_brand}
                      className="xl:w-[60px] w-[32.89px] xl:h-[60px] h-[32.89px] xl:-mb-[30px] -mb-[12px]"
                    />
                    <div className="h-full">
                      <div className="bg-[#E9E9EB] xl:max-w-[316px] max-w-[174px] w-full xl:py-[14px] py-[8px] xl:px-[25px] px-[13px] xl:rounded-t-[28.2293px] rounded-t-[15.476px] xl:rounded-br-[28.2293px] rounded-br-[15.476px]">
                        <p className="xl:text-[24.7006px] text-[13.54px] xl:leading-[30px] leading-[16.53px] text-black font-medium">
                          Thanks for joining Jane! Do you play?
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="flex items-end gap-x-[16px] justify-end xl:mt-[44px] mt-[22px]">
                      <div className="h-full">
                        <div className="bg-[#418EF6] xl:max-w-[276px] max-w-[152px] w-full relative xl:py-[14px] py-[8px] xl:px-[15px] px-[13px] xl:rounded-t-[28.2293px] rounded-t-[15.476px] xl:rounded-bl-[28.2293px] rounded-bl-[15.476px]">
                          <img
                            alt="reaction"
                            src={reaction}
                            className="xl:w-[100.69px] w-[67.04px] xl:h-[68.93px] h-[41.9px] object-contain absolute xl:right-[87%] right-[82%] xl:-mt-[35px] -mt-[26px]"
                          />
                          <p className="xl:text-[24.7006px] text-[13.54px] xl:leading-[30px] leading-[16.53px] text-white font-medium">
                            Just started playing actually!
                          </p>
                        </div>
                      </div>
                      <img
                        alt="avatar"
                        src={user_1}
                        className="xl:w-[60px] w-[32.89px] xl:h-[60px] h-[32.89px] xl:-mb-[30px] -mb-[12px]"
                      />
                    </div>
                  </div>
                  <div className="flex items-end gap-x-[16px] xl:mt-[44px] mt-[22px]">
                    <img
                      alt="brand"
                      src={new_brand}
                      className="xl:w-[60px] w-[32.89px] xl:h-[60px] h-[32.89px] xl:-mb-[30px] -mb-[12px]"
                    />
                    <div className="h-full">
                      <div className="bg-[#E9E9EB] xl:max-w-[316px] max-w-[174px] w-full xl:py-[14px] py-[8px] xl:px-[25px] px-[13px] xl:rounded-t-[28.2293px] rounded-t-[15.476px] xl:rounded-br-[28.2293px] rounded-br-[15.476px]">
                        <p className="xl:text-[24.7006px] text-[13.54px] xl:leading-[30px] leading-[16.53px] text-black font-medium">
                          Amazing. Just sent you a reward that should help your
                          game - grab it in the Redeem tab!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <h2 className="xl:text-[35px] text-[24px]  xl:leading-[38.5px] leading-[28.8px] text-center text-black font-semibold xl:pt-[120px] pt-[80px]">
                  We’re starting by rethinking community in sports.
                </h2>
                <p className="xl:text-[24px] text-[18px]  xl:leading-[140%] leading-[25.2px] font-normal text-black text-center mt-[15px]">
                  Athletes and teams connect directly with fans. Fans get
                  rewarded for sharing who they are.
                  <br />
                  <br className="md:hidden" />
                  That’s a win.
                </p>
                <div className="mt-[50px]">
                  <div className="sm:max-w-[390px] relative w-full max-w-[324px] sm:max-h-[843px] h-full max-h-[700.34px] mx-auto">
                    {/* <img
                      src={gif}
                      alt="Successful earned points"
                      className="sm:h-[242px] h-[180px] sm:w-[242px] w-[180px] absolute z-10 sm:top-[12%] top-[13%] left-1/2 -translate-x-1/2"
                    /> */}
                    <img
                      alt="scoring"
                      src={scoringd}
                      className="object-contain w-full h-full rounded-t-[28px]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="bg-[#EEE8DB] xl:pb-[111px] pb-[80px]">
            <div className="container mx-auto max-w-[1203px] px-[15px]">
              <h2 className="xl:text-[35px] text-[24px] xl:leading-[38.5px] leading-[28.8px] text-center text-black font-semibold xl:pt-[120px] pt-[80px] px-5">
                This is how we turn identity into an asset.
              </h2>
              <h2 className="xl:text-[35px] text-[24px] xl:leading-[38.5px] leading-[28.8px] sm:mt-0 mt-[40px] text-center text-black font-semibold xl:pb-[40px] pb-[50px]">
                Are you a fan?
              </h2>
              <a
                rel="noopener noreferrer"
                href="https://form.typeform.com/to/MzXJVwrN"
                target={"_blank"}
                className="bg-[#F6104E] uppercase flex items-center justify-center text-white mx-auto w-[182px] h-[55px] rounded-[10px] text-[18px] tracking-[0.03em] font-normal"
              >
                I’M A FAN
              </a>
            </div>
          </section>
          <footer className="bg-[#1D1D1D] py-[60px]">
            <div className="container mx-auto max-w-[1203px] px-[15px]">
              <Link to="/">
                <img
                  alt="logo"
                  src={logo}
                  className="w-[170.39px] h-[26.28px] object-contain mx-auto"
                />
              </Link>
              <div className="mt-[40px] flex flex-col  items-center justify-center mx-auto gap-y-[15px]">
                <a
                  href={common.privacy_policy_url}
                  target="_blank"
                  className="text-[16px] text-white font-normal underline leading-[22.4px]" rel="noreferrer"
                >
                  Privacy Policy
                </a>
                <a
                  href={common.term_of_service_url}
                  target="_blank"
                  className="text-[16px] text-white font-normal underline leading-[22.4px]" rel="noreferrer"
                >
                  Terms of Service
                </a>

                <p className="text-[14px] text-white text-center font-normal mt-[40px] leading-[19.6px]">
                  Encore Fans, Inc. | Examples reflect our team's fandom, and are not necessarily
                  indications of our customers.
                </p>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
}

export default Start;
