import { getAppName } from "@helpers/common";
import socialService from "@services/social-service";
import store from "@store/configureStore";

function socialAuthPopup(url:any, type:any){
  const popup = window.open(url, "_self");
}

//twitter authentication start
export function handleTwitterAuth(state = Math.random().toString()) {
  const SCOPE =
    getAppName() === "creator"
      ? process.env.REACT_APP_CREATOR_TWITTER_SCOPE
      : process.env.REACT_APP_TWITTER_SCOPE;

  const redirect_uri = getAppName() === "creator"? process.env.REACT_APP_TWITTER_CREATOR_REDIRECT_URL:process.env.REACT_APP_TWITTER_REDIRECT_URL

  let url = process.env.REACT_APP_TWITTER_ENDPOINT_URL;

  url += "?client_id=" + process.env.REACT_APP_TWITTER_API_KEY;
  url += "&scope=" + SCOPE;
  url += "&response_type=" + process.env.REACT_APP_TWITTER_RESPONSE_TYPE;
  url += "&redirect_uri=" + redirect_uri;
  url += "&code_challenge=" + process.env.REACT_APP_TWITTER_CHALLENGE;
  url +=
    "&code_challenge_method=" + process.env.REACT_APP_TWITTER_CHALLENGE_METHOD;
  
  if(getAppName() === "creator"){
    url += "&state=" +state;
    window.open(url, "_self");
  } else {
    socialService.connect("twitter", url, state);
  }
  
}

//discord authentication start
export function handleDiscordAuth() {
  const SCOPE =
    getAppName() === "creator"
      ? process.env.REACT_APP_CREATOR_DISCORD_SCOPE
      : process.env.REACT_APP_DISCORD_SCOPE;

  const CLIENT_ID = process.env.REACT_APP_DISCORD_CLIENT_ID;
  const REDIRECT_URI = process.env.REACT_APP_DISCORD_REDIRECT_URL;
  const AUTH_ENDPOINT = process.env.REACT_APP_DISCORD_AUTH_ENDPOINT;
  const RESPONSE_TYPE = process.env.REACT_APP_DISCORD_RESPONSE_TYPE;
  const grant_type = process.env.REACT_APP_DISCORD_GRANT_TYPE;
  const STATE = getAppName();
  let url = `${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&scope=${SCOPE}&grant_type=${grant_type}&state=${STATE}`;

  window.open(url, "_self");
}
//discord authentication end

//spotify authentication start
export function handleSpotifyAuth() {
  const CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
  const REDIRECT_URI = process.env.REACT_APP_SPOTIFY_REDIRECT_URL;
  const AUTH_ENDPOINT = process.env.REACT_APP_SPOTIFY_AUTH_ENDPOINT;
  const RESPONSE_TYPE = process.env.REACT_APP_SPOTIFY_RESPONSE_TYPE;
  const STATE = getAppName();
  let url = `${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&state=${STATE}`;

  window.open(url, "_self");
}

//shopify authentication start
export function handleShopifyAuth(store
) {

  const SCOPE =
    getAppName() === "creator"
      ? process.env.REACT_APP_CREATOR_SHOPIFY_SCOPE
      : process.env.REACT_APP_SHOPIFY_SCOPE;

  const state = getAppName();
  const api_key = process.env.REACT_APP_SHOPIFY_API_KEY;
  const redirect_uri = process.env.REACT_APP_SHOPIFY_REDIRECT_URL;
  const access_mode = process.env.REACT_APP_SHOPIFY_MODE;
  const shop = store.replace("https://", "").replace(".myshopify.com", "");
  const url = `https://${shop}.myshopify.com/admin/oauth/authorize?client_id=${api_key}&scope=${SCOPE}&redirect_uri=${redirect_uri}&state=${state}&grant_options[]=${access_mode}`;

  window.open(url, "_self");
}

export function handleFanShopifyAuth(store
) {

  const SCOPE =
    getAppName() === "fans"
      ? process.env.REACT_APP_CREATOR_SHOPIFY_SCOPE
      : process.env.REACT_APP_SHOPIFY_SCOPE;

  const state = getAppName()
  const api_key = process.env.REACT_APP_SHOPIFY_API_KEY;
  const redirect_uri = process.env.REACT_APP_SHOPIFY_REDIRECT_URL;
  const access_mode = process.env.REACT_APP_SHOPIFY_MODE;
  const shop = store.replace("https://", "").replace(".myshopify.com", "");
  const url = `https://${shop}.myshopify.com/admin/oauth/authorize?client_id=${api_key}&scope=${SCOPE}&redirect_uri=${redirect_uri}&state=${state}&grant_options[]=${access_mode}`;

  window.open(url, "_self");
}

//tiktok authentication start
export function handleTiktokAuth(state=Math.random().toString()) {
  const SCOPE =
    getAppName() === "creator"
      ? process.env.REACT_APP_CREATOR_TIKAPI_SCOPE
      : process.env.REACT_APP_TIKTOK_SCOPE;

  let url =
    getAppName() === "creator"
      ? process.env.REACT_APP_TIKAPI_ENDPOINT_URL
      : process.env.REACT_APP_TIKTOK_ENDPOINT_URL;

  const redirect_url =
      getAppName() === "creator"
        ? process.env.REACT_APP_TIKTOK_CREATOR_REDIRECT_URL
        : process.env.REACT_APP_TIKTOK_REDIRECT_URL;
  

  if (getAppName() === "creator") {
    url += "?client_id=" + process.env.REACT_APP_TIKAPI_OATUH_CLIENT_ID;
  } else {
    url += "?client_key=" + process.env.REACT_APP_TIKTOK_CLIENT_KEY;
  }

  url += "&scope=" + SCOPE;
  if (getAppName() !== "creator") {
    url += "&response_type=" + process.env.REACT_APP_TIKTOK_RESPONSE_TYPE;
  }
  url += "&redirect_uri=" + redirect_url;

  if(getAppName() === "creator"){
    url += "&state=" +state;
    window.open(url, "_self");
  } else {
    socialService.connect("tiktok", url, state);
  }
}

//instagram authentication start
export function handleInstagramAuth(state=Math.random().toString()) {
  const SCOPE =
    getAppName() === "creator"
      ? process.env.REACT_APP_CREATOR_INSTAGRAM_SCOPE
      : process.env.REACT_APP_INSTAGRAM_SCOPE;
  const redirect_uri = getAppName() === "creator"? process.env.REACT_APP_INSTAGRAM_CREATOR_REDIRECT_URL:process.env.REACT_APP_INSTAGRAM_REDIRECT_URL

  let url = process.env.REACT_APP_INSTAGRAM_ENDPOINT_URL;
  url += "?client_id=" + process.env.REACT_APP_INSTAGRAM_CLIENT_ID;
  url += "&scope=" + SCOPE;
  url += "&response_type=" + process.env.REACT_APP_INSTAGRAM_RESPONSE_TYPE;
  url += "&redirect_uri=" + redirect_uri;
  if(getAppName() === "creator"){
    url += "&state=" +state;
    window.open(url, "_self");
  } else {
   
    socialService.connect("instagram", url, state);
  }


}

//youtube authentication start
export function handleYoutubeAuth(state=Math.random().toString()) {
  const SCOPE =
    getAppName() === "creator"
      ? process.env.REACT_APP_CREATOR_YOUTUBE_SCOPE
      : process.env.REACT_APP_YOUTUBE_SCOPE;

    const redirect_uri = getAppName() === "creator"? process.env.REACT_APP_YOUTUBE_CREATOR_REDIRECT_URL:process.env.REACT_APP_YOUTUBE_REDIRECT_URL


  let url = process.env.REACT_APP_YOUTUBE_ENDPOINT_URL;
  url += "?client_id=" + process.env.REACT_APP_YOUTUBE_CLIENT_ID;
  url += "&scope=" + SCOPE;
  url += "&response_type=" + process.env.REACT_APP_YOUTUBE_RESPONSE_TYPE;
  url += "&redirect_uri=" + redirect_uri;
  url += "&prompt=" + process.env.REACT_APP_YOUTUBE_PROMPT;
  url += "&access_type=" + process.env.REACT_APP_YOUTUBE_ACCESS_TYPE;

  if(getAppName() === "creator"){
    url += "&state=" +state;
    window.open(url, "_self");
  } else {
    socialService.connect("youtube", url, state);
  }
}

//twitch authentication start
export function handleTwitchAuth() {
  const SCOPE =
    getAppName() === "creator"
      ? process.env.REACT_APP_CREATOR_TWITCH_SCOPE
      : process.env.REACT_APP_TWITCH_SCOPE;

  let url = process.env.REACT_APP_TWITCH_ENDPOINT_URL;
  url += "?client_id=" + process.env.REACT_APP_TWITCH_CLIENT_ID;
  url += "&scope=" + SCOPE;
  url += "&response_type=" + process.env.REACT_APP_TWITCH_RESPONSE_TYPE;
  url += "&redirect_uri=" + process.env.REACT_APP_TWITCH_REDIRECT_URI;
  url += "&state=" + getAppName();

  window.open(url, "_self");


}

export function handleFacebookAuth(state=Math.random().toString()) {
  const SCOPE =
    getAppName() === "creator"
      ? process.env.REACT_APP_CREATOR_FACEBOOK_SCOPE
      : process.env.REACT_APP_FACEBOOK_SCOPE;

    const redirect_uri = getAppName() === "creator"? process.env.REACT_APP_FACEBOOK_CREATOR_REDIRECT_URL:process.env.REACT_APP_FACEBOOK_REDIRECT_URL


  let url = process.env.REACT_APP_FACEBOOK_ENDPOINT_URL;
  url += "?client_id=" + process.env.REACT_APP_FACEBOOK_CLIENT_ID;
  url += "&scope=" + SCOPE;
  url += "&response_type=" + process.env.REACT_APP_FACEBOOK_RESPONSE_TYPE;
  url += "&redirect_uri=" + redirect_uri;

  if(getAppName() === "creator"){
    url += "&state=" +state;
    window.open(url, "_self");
  } else {
    socialService.connect("facebook", url, state);
  }
}

export function handleSocialAuth(type, store=null) {
  switch (true) {
    case type === "x":
      handleTwitterAuth();
      break;
    case type === "tiktok":
      handleTiktokAuth();
      break;
    case type === "instagram":
      handleInstagramAuth();
      break;
    case type === "discord":
      handleDiscordAuth();
      break;
    case type === "spotify":
      handleSpotifyAuth();
      break;
    case type === "shopify":
      handleShopifyAuth(store);
      break;
    case type === "youtube":
      handleYoutubeAuth();
      break;
    case type === "twitch":
      handleTwitchAuth();
      break;
    case type === "facebook":
      handleFacebookAuth();
      break;
    default:
      break;
  }
}

export function handleFanSocialAuth(type, tpId = null, action:any={}) {
  const state = `${tpId}~${action?.id}~${action?.points || "0"}~${store.getState().user?.user?.user?.id}~${store.getState().creator?.program?.id}`;
  switch (true) {
    case type === "twitter":
      handleTwitterAuth(state);
      break;
    case type === "tiktok":
      handleTiktokAuth(state);
      break;
    case type === "instagram":
      handleInstagramAuth(state);
      break;
    case type === "discord":
      handleDiscordAuth();
      break;
    case type === "spotify":
      handleSpotifyAuth();
      break;
    case type === "shopify":
      handleFanShopifyAuth("");
      break;
    case type === "youtube":
      handleYoutubeAuth(state);
      break;
    case type === "twitch":
      handleTwitchAuth();
      break;
    case type === "facebook":
      handleFacebookAuth(state);
      break;
    default:
      break;
  }
}


export function handleConnectButton(touchpoint, userInfo) {
  const { user } = userInfo || {};
  let status = false;
  touchpoint = (touchpoint || "").toLowerCase();
  switch (true) {
    case touchpoint === "twitter" &&
      typeof (user || {}).twitterId === "undefined":
      status = true;
      break;
    case touchpoint === "instagram" &&
      typeof (user || {}).instagramId === "undefined":
      status = true;
      break;
    case touchpoint === "tiktok" &&
      typeof (user || {}).tiktokId === "undefined":
      status = true;
      break;
    case touchpoint === "spotify" &&
      typeof (user || {}).spotifyId === "undefined":
      status = true;
      break;
    case touchpoint === "shopify" &&
      typeof (user || {}).shopifyId === "undefined":
      status = true;
      break;
    case touchpoint === "discord" &&
      typeof (user || {}).discordId === "undefined":
      status = true;
      break;
    case touchpoint === "youtube" &&
      typeof (user || {}).youtubeId === "undefined":
      status = true;
      break;
    case touchpoint === "twitch" &&
      typeof (user || {}).twitchId === "undefined":
      status = true;
      break;
    case touchpoint === "facebook" &&
      typeof (user || {}).facebookId === "undefined":
      status = true;
      break;
    default:
      break;
  }
  return status;
}

export const isTouchPointConnected = (name, touchpoints) => {
  return Object.assign({}, (touchpoints || []).filter(i => i.touchpoint.touchpoint == name)?.[0]);
}

export function connectedTouchpoinnts(touchpoints) {
  return Object.assign([], (touchpoints || []).map(i => i.touchpoint.touchpoint));
}

export function isTouchpointConnect(item, { user }) {
  let status = false;
  let touchpoint = item?.touchpoint?.touchpoint || "";
  switch (true) {
    case touchpoint === "twitter" &&
      typeof (user || {}).twitterId !== "undefined" &&
      item?.authorized:
      status = true;
      break;
    case touchpoint === "instagram" &&
      typeof (user || {}).instagramId !== "undefined" &&
      item?.authorized:
      status = true;
      break;
    case touchpoint === "tiktok" &&
      typeof (user || {}).tiktokId !== "undefined" &&
      item?.authorized:
      status = true;
      break;
    case touchpoint === "spotify" &&
      typeof (user || {}).spotifyId !== "undefined" &&
      item?.authorized:
      status = true;
      break;
    case touchpoint === "shopify" &&
      typeof (user || {}).shopifyId !== "undefined" &&
      item?.authorized:
      status = true;
      break;
    case touchpoint === "discord" &&
      typeof (user || {}).discordId !== "undefined" &&
      item?.authorized:
      status = true;
      break;
    case touchpoint === "youtube" &&
      typeof (user || {}).youtubeId !== "undefined" &&
      item?.authorized:
      status = true;
      break;
    case touchpoint === "twitch" &&
      typeof (user || {}).twitchId !== "undefined" &&
      item?.authorized:
      status = true;
      break;
    case touchpoint === "facebook" &&
      typeof (user || {}).facebookId !== "undefined" &&
      item?.authorized:
      status = true;
      break;
    default:
      break;
  }
  return status;
}

export function socialTouchpoints() {
  return [
    "twitter",
    "twitch",
    "tiktok",
    // "instagram",
    //"facebook",
    //"spotify",
    "shopify",
    //"discord",
    "youtube",
  ];
}

export function fanSocialTouchpoints() {
  return [
    "twitter",
    "twitch",
    "tiktok",
    "instagram",
    "facebook",
    //"spotify",
    "shopify",
    //"discord",
    "youtube",
  ];
}

export const redirectUri = touchpoint => {
  switch (touchpoint) {
    case "twitter":
      return process.env.REACT_APP_TWITTER_REDIRECT_URL;
    case "instagram":
      return process.env.REACT_APP_INSTAGRAM_REDIRECT_URL;
    case "youtube":
      return process.env.REACT_APP_YOUTUBE_REDIRECT_URI;
    case "spotify":
      return process.env.REACT_APP_SPOTIFY_REDIRECT_URL;
    case "discord":
      return process.env.REACT_APP_DISCORD_REDIRECT_URL;
    case "tiktok":
      return process.env.REACT_APP_TIKTOK_REDIRECT_URL;
    case "shopify":
      return process.env.REACT_APP_SHOPIFY_REDIRECT_URL;
    case "twitch":
      return process.env.REACT_APP_TWITCH_REDIRECT_URI;
    case "facebook":
      return process.env.REACT_APP_FACEBOOK_REDIRECT_URL;
    default:
      return "";
  }
};

export const getScore = touchpoint => {
  touchpoint = (touchpoint || "").toLowerCase();
  switch (touchpoint) {
    case "twitter":
      return {
        score: {
          existingFollow: 100,
          newFollow: 250,
          retweet: 150,
          like: 100,
        },
      };
    case "tiktok":
      return {
        score: {
          existingFollow: 100,
          newFollow: 250,
          like: 100,
        },
      };
    case "instagram":
      return {
        score: {
          existingFollow: 100,
          newFollow: 250,
          like: 100,
        },
      };
    case "youtube":
        return {
          score: {
            newFollow: 250,
            like: 100,
          },
        };
    case "twitch":
      return {
        score: {
          follow: 250,
          subscription: { points: 1500, weight: [0.2, 0.4, 1] },
          bit: 500,
          gift: { points: 1500, pointsCumulative: 0 },
        },
      };
    default:
      return { score: {} };
  }
};
